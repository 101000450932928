import React from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import { Collapse } from "reactstrap";
import user from "../../../assets/images/user.png";
import { InputField, LabelField } from "../../common/form";
import VariantField from "../../common/product/variantField";
import { variantValidatorSchema } from "../../../utils/validators";
import {
  UPDATE_PRODUCT_VARIANT,
  ADD_FEATURE,
  EDIT_FEATURE,
  DELETE_FEATURE
} from "../../../constants/ActionTypes";

const initialState = {
  price_addon: 0,
  variant_image: {},
  current_variant_image: { img: user },
  variant_name: "",
  sku: "",
  size: "",
  color: "",
  features: []
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PRODUCT_VARIANT:
      return {
        ...state,
        ...payload
      };

    case ADD_FEATURE:
      return {
        ...state,
        features: [
          ...state.features,
          {
            name: "",
            value: ""
          }
        ]
      };

    case EDIT_FEATURE:
      let featuresToUpdate = state.features;
      let { idx, field, value } = payload;
      featuresToUpdate[idx][field] = value;

      return {
        ...state,
        features: featuresToUpdate
      };

    case DELETE_FEATURE:
      return {
        ...state,
        features: [
          ...state.features.filter((feature, idx) => idx !== payload.idx)
        ]
      };

    default: {
      throw new Error("Invalid action type");
    }
  }
}

function AddProductVariantModal({ isOpen, onClose, onSubmit }) {
  const [formData, validation, validateForm, getData] = useForm(
    initialState,
    variantValidatorSchema
  );

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { features, variant_image, current_variant_image } = state;
  const isFeaturesPanelVisible = features.length > 0;

  let handleFeatureDelete = (e, idx) => {
    e.preventDefault();
    dispatch({ type: DELETE_FEATURE, payload: { idx } });
  };

  let handleFeatureEdit = (e, { idx, field }) => {
    e.preventDefault();
    let { value } = e.target;
    dispatch({ type: EDIT_FEATURE, payload: { idx, field, value } });
  };

  let handleSubmit = e => {
    e.preventDefault();
    if (!validateForm()) return;
    let data = {
      ...getData(),
      variant_image: [variant_image],
      features
    };

    return onSubmit(data);
  };

  let handleImgChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      dispatch({
        type: UPDATE_PRODUCT_VARIANT,
        payload: {
          current_variant_image: {
            img: reader.result
          },
          variant_image: reader.result
        }
      });
    };
    reader.readAsDataURL(file);
  };

  let handleImgSubmit = e => {
    e.preventDefault();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <form className="section-b-space" onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <h3 className="text-center w-100 py-3">Add a product variant.</h3>
          </div>
          <div className="col-12 py-3">
            <div className="form-group row">
              <LabelField label="Variant Image" />
              <div className="col-xl-8 col-md-7">
                <div className="add-product">
                  <ul className="file-upload-product">
                    <li>
                      <div
                        className="box-input-file rounded-circle"
                        style={{ border: "3px solid #ff8084" }}
                      >
                        <input
                          className="upload"
                          type="file"
                          onChange={handleImgChange}
                        />
                        <img
                          alt=""
                          src={current_variant_image.img}
                          style={{
                            width: 50,
                            height: 50
                          }}
                          className="rounded-circle"
                        />
                        <a
                          href="/"
                          id="result1"
                          onClick={e => handleImgSubmit(e.target.id)}
                        >
                          {""}
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <InputField
              validation={validation}
              formData={formData}
              label={"Variant Name"}
              field={"variant_name"}
            />
            <InputField
              validation={validation}
              formData={formData}
              label={"Price addon"}
              field={"price_addon"}
              type="number"
            />
            <InputField
              validation={validation}
              formData={formData}
              label={"SKU"}
              field={"sku"}
            />
            <InputField
              validation={validation}
              formData={formData}
              label={"Color"}
              field={"color"}
            />
            <InputField
              validation={validation}
              formData={formData}
              label={"Size"}
              field={"size"}
            />

            <Collapse isOpen={isFeaturesPanelVisible}>
              {isFeaturesPanelVisible ? null : (
                <VariantField
                  onEdit={handleFeatureEdit}
                  onDelete={handleFeatureDelete}
                />
              )}
              {features.map(({ name, value }, idx) => (
                <VariantField
                  name={name}
                  value={value}
                  key={idx}
                  idx={idx}
                  onEdit={handleFeatureEdit}
                  onDelete={handleFeatureDelete}
                />
              ))}
            </Collapse>

            <div className="form-group">
              <a
                href="#/"
                onClick={e => {
                  e.preventDefault();
                  dispatch({
                    type: ADD_FEATURE
                  });
                }}
                className="font-weight-bold"
              >
                <i className="fa fa-plus"></i> Add new variant feature
              </a>
            </div>

            <div className="offset-xl-3 offset-sm-4">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button type="button" onClick={onClose} className="btn btn-light">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default AddProductVariantModal;
