import React from "react";
import { formatErrs } from "../../../utils/validators";
import { LabelField } from "./index";

export function SelectField({
  validation,
  field,
  value,
  formData,
  label,
  options,
  onChange
}) {
  let optionsList =
    options &&
    options.map((option, key) => (
      <option value={option.value} key={key}>
        {option.label}
      </option>
    ));

  return (
    <div className="form-group row">
      {label && <LabelField label={label} />}

      <div className="col-xl-8 col-md-7">
        <select
          className="form-control"
          id={field}
          {...formData[field].input}
          onChange={e => {
            typeof onChange === "function" && onChange(e);
            formData[field].input.onChange(e);
          }}
          name={field}
          required
          defaultValue={value}
        >
          {optionsList}
        </select>
        {validation.errors[field] && (
          <p className="text text-danger">
            {formatErrs(validation.errors[field])}
          </p>
        )}
      </div>
    </div>
  );
}
