import React from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import { Collapse } from "reactstrap";
import {
  InputField,
  RadioField,
  SelectField,
  LabelField,
  RichTextField
} from "../common/form";
import { productValidatorSchema } from "../../utils/validators";
import { ImageField } from "../common/form";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import { SketchPicker } from "react-color";

const UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE";

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PRODUCT_STATE:
      return {
        ...state,
        ...payload
      };

    default: {
      throw new Error("Invalid action type");
    }
  }
}

export function ProductEditModal({
  id,
  product_name,
  price,
  description,
  main_product_images,
  is_enabled,
  stock,
  tags,
  discount,
  category,
  categories,
  sub_category,
  sizes = [],
  subCategories,
  isOpen,
  onSubmit,
  onClose,
  colors = [],
  onImageSubmit
}) {
  const formState = {
    product_name,
    price,
    discount,
    is_published: is_enabled,
    total_stock: stock,
    category_id: category.id,
    sub_category_id: sub_category.id,
    main_product_images,
    product_image: null,
    paletteList: [...colors],
    images: [...main_product_images]
  };
  const initialState = {
    ...formState,
    palette: "#ccc",
    tags: tags.length ? tags.map(({ tag }) => tag) : [],
    sizes: sizes.length ? sizes.map(({ size }) => size) : [],
    isProductVariantModalOpen: false
  };

  let [formData, validation, validateForm, getData] = useForm(
    formState,
    productValidatorSchema
  );

  let [state, dispatch] = React.useReducer(reducer, initialState);
  let handleSubmit = e => {
    e.preventDefault();
    if (!validateForm()) return;
    const { product_image } = state;
    let data = {
      id,
      ...getData(),
      tags: state.tags,
      sizes: state.sizes,
      product_image,
      colors: state.paletteList
    };

    return onSubmit(data);
  };

  let handleImageChange = (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    dispatch({
      type: UPDATE_PRODUCT_STATE,
      payload: {
        product_image: file
      }
    });

    reader.onloadend = () => {
      let newImages = [...state.images];
      newImages[i].image_url = reader.result;
      dispatch({
        type: UPDATE_PRODUCT_STATE,
        payload: {
          images: newImages
        }
      });
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <form className="section-b-space" onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <h3 className="text-center w-100 py-3">
                Update product information
              </h3>
              <div className="col-12 py-3">
                <div className="form-group row">
                  <LabelField label={"Product Image(s)"} />
                  <ImageField
                    images={state.images}
                    onChange={handleImageChange}
                    onSubmit={onImageSubmit}
                  />
                </div>
                <InputField
                  validation={validation}
                  formData={formData}
                  label={"Product Name"}
                  field={"product_name"}
                />
                <InputField
                  validation={validation}
                  formData={formData}
                  label={"Price"}
                  field={"price"}
                  type={"number"}
                />
                <InputField
                  validation={validation}
                  formData={formData}
                  label={"Discount"}
                  field={"discount"}
                  type={"number"}
                />
                <div className="form-group mb-3 row">
                  <label className="col-xl-3 col-sm-4 mb-0">Tags:</label>
                  <div className="col-xl-8 col-sm-7">
                    <TagsInput
                      value={state.tags}
                      addKeys={[9, 13, 32]}
                      inputProps={{
                        placeholder: "Type a tag"
                      }}
                      tagProps={{
                        className: "bg-dark text-white react-tagsinput-tag",
                        classNameRemove: "text-white react-tagsinput-remove"
                      }}
                      onChange={tags =>
                        dispatch({
                          type: UPDATE_PRODUCT_STATE,
                          payload: { tags }
                        })
                      }
                    />
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-xl-3 col-sm-4 mb-0">Sizes:</label>
                  <div className="col-xl-8 col-sm-7">
                    <TagsInput
                      value={state.sizes}
                      addKeys={[9, 13, 32]}
                      inputProps={{
                        placeholder: "Type size"
                      }}
                      onChange={sizes =>
                        dispatch({
                          type: UPDATE_PRODUCT_STATE,
                          payload: { sizes }
                        })
                      }
                    />
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </div>
                <InputField
                  validation={validation}
                  formData={formData}
                  label={"Stock"}
                  field={"total_stock"}
                  type={"number"}
                />
                <div className="form-group ml-2 my-3 row">
                  <label className="col-xl-3 col-sm-4 mb-0">Palette:</label>
                  <div className="col-xl-3 col-sm-4 row mb-0">
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => {
                        dispatch({
                          type: UPDATE_PRODUCT_STATE,
                          payload: {
                            paletteList: [...state.paletteList, state.palette]
                          }
                        });
                      }}
                    >
                      Add {state.paletteList.length > 0 ? "new " : ""} color
                    </button>

                    <Collapse isOpen={state.paletteList.length > 0}>
                      <div className="d-flex">
                        {state.paletteList.map((paletteItem, key) => {
                          return (
                            <div className="m-2 p-relative" key={key}>
                              <i
                                onClick={() => {
                                  dispatch({
                                    type: UPDATE_PRODUCT_STATE,
                                    payload: {
                                      paletteList: state.paletteList.filter(
                                        item => item !== paletteItem
                                      )
                                    }
                                  });
                                }}
                                className="fa fa-close text-danger"
                              ></i>
                              <div
                                className="mb-2 mr-1"
                                style={{
                                  backgroundColor: paletteItem,
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "100%",
                                  boxShadow: "0 0 2px #aaa"
                                }}
                                key={key}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <SketchPicker
                        color={state.palette}
                        onChangeComplete={color => {
                          let paletteList = state.paletteList;
                          paletteList[paletteList.length - 1] = color.hex;

                          dispatch({
                            type: UPDATE_PRODUCT_STATE,
                            payload: { palette: color.hex }
                          });
                        }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </Collapse>
                  </div>
                </div>

                <SelectField
                  validation={validation}
                  formData={formData}
                  field={"category_id"}
                  label="Category"
                  options={categories}
                />
                {subCategories.length > 0 && (
                  <Collapse isOpen>
                    <SelectField
                      validation={validation}
                      formData={formData}
                      field={"sub_category_id"}
                      label="Sub Category"
                      value={sub_category.id}
                      options={subCategories}
                    />
                  </Collapse>
                )}
                <RichTextField
                  onBlur={() => {}}
                  onChange={() => {}}
                  afterPaste={() => {}}
                  label={"Edit Description"}
                  content={description}
                />
                <RadioField
                  label={"Publish this item?"}
                  validation={validation}
                  formData={formData}
                  field={"is_published"}
                  value={is_enabled}
                  options={[
                    { label: "Yes", value: 1 },
                    { label: "No", value: 0 }
                  ]}
                />
                <div className="offset-xl-3 offset-sm-4">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="btn btn-light"
                  >
                    Cancel
                  </button>
                  {/* <button
                    type="button"
                    onClick={() => toggleProductVariantModal()}
                    className="btn btn-link mb-3"
                  >
                    <i className="fa fa-plus"></i> Add product variant
                  </button>

                  <VariantsList variants={variants} /> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
