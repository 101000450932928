import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { ProductEditModal } from "./productEditModal";
import {
  fetchStoreProducts,
  fetchProductCategories,
  fetchProductSubCategories,
  createProductVariant,
  updateProduct,
  removeProduct
} from "../../actions";
import user from "../../assets/images/user.png";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import asFormData from "json-form-data";
import { prepareSelectOptions } from "../../utils";

const PRODUCTS_PAGINATION_SIZE = 25;

const transformProductListData = products => {
  if (!products || !products.length) return products;
  let renderProductThumb = src => (
    <div>
      <img
        src={src}
        alt="img"
        style={{
          width: "40px",
          height: "40px"
        }}
        className="img-fluid rounded-circle blur-up lazyloaded mr-3"
      />
    </div>
  );

  return products.map(
    ({ id, product_name, main_product_images, price, is_enabled, stock }) => {
      let img = main_product_images.length
        ? main_product_images[0].image_url
        : user;

      return {
        id,
        img: renderProductThumb(img),
        name: product_name,
        price,
        stock,
        is_published: is_enabled ? "yes" : "no"
      };
    }
  );
};

export class ListProducts extends Component {
  state = {
    product: null,
    isProductModalOpen: false
  };

  constructor(props) {
    super(props);
    this.onAddProductVariant = this.onAddProductVariant.bind(this);
    this.closeProductModal = this.closeProductModal.bind(this);
  }

  componentDidMount() {
    const { fetchStoreProducts, fetchProductCategories } = this.props;
    fetchStoreProducts(
      {
        page: 1,
        limit: PRODUCTS_PAGINATION_SIZE
      },
      function() {}
    );
    fetchProductCategories();
  }

  onAddProductVariant(variant, callback) {
    const { product } = this.state;
    this.props.addProductVariant(
      {
        variant: asFormData(variant),
        productId: product.id
      },
      callback
    );
  }

  closeProductModal() {
    this.setState({ isProductModalOpen: false });
  }

  render() {
    const { product, isProductModalOpen } = this.state;
    const {
      products,
      subCategories,
      categories,
      fetchProductSubCategories,
      updateProduct,
      removeProduct
    } = this.props;
    let data = transformProductListData(products);
    return (
      <Fragment>
        {product ? (
          <ProductEditModal
            isOpen={isProductModalOpen}
            onClose={this.closeProductModal}
            onSubmit={data => {
              updateProduct(data.id, asFormData(data), () => {
                this.closeProductModal();
                this.props.fetchStoreProducts({
                  page: 1,
                  limit: PRODUCTS_PAGINATION_SIZE
                });
              });
            }}
            onAddProductVariant={this.onAddProductVariant}
            {...product}
            variants={product.variants || []}
            categories={prepareSelectOptions({
              items: categories,
              label: "category_label",
              value: "id"
            })}
            subCategories={prepareSelectOptions({
              items: subCategories,
              label: "sub_category_label",
              value: "id"
            })}
          />
        ) : null}
        <Breadcrumb title="Products List" parent="Products" />
        <div className="container-fluid">
          {data.length ? (
            <div className="card">
              <div className="card-header">
                <h5>All Products</h5>
              </div>
              <div className="card-body vendor-table coupon-list-delete">
                <Datatable
                  multiSelectOption={false}
                  myData={data}
                  onEdit={item => {
                    const { id } = item;
                    const isProduct = product => product.id === id;
                    const product = products.filter(isProduct)[0];
                    if (product) {
                      // fetchProductSubCategories(product.category.id);
                      this.setState({ product, isProductModalOpen: true });
                    }
                  }}
                  onDelete={item => {
                    removeProduct(item.id);
                  }}
                  pageSize={PRODUCTS_PAGINATION_SIZE}
                  pagination={true}
                  className="-striped -highlight"
                />
              </div>
            </div>
          ) : (
            <div className="container-fluid">
              <div className="card">
                <div className="card-body vendor-table coupon-list-delete">
                  <Spinner type="grow" size="sm" color="success" />
                </div>
                <img
                  style={{ maxWidth: "100%", margin: "auto" }}
                  src="https://i.ibb.co/rfsrs1H/undraw-clean-up-ucm0.png"
                  alt="undraw-clean-up-ucm0"
                />
                <p className="text-center lead mb-4">
                  Products to be displayed are being fetched...
                </p>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.store.products,
    categories: state.store.categories,
    subCategories: state.store.subCategories
  };
};

const mapDispatchToProps = dispatch => ({
  fetchStoreProducts: bindActionCreators(fetchStoreProducts, dispatch),
  fetchProductCategories: bindActionCreators(fetchProductCategories, dispatch),
  fetchProductSubCategories: bindActionCreators(
    fetchProductSubCategories,
    dispatch
  ),
  addProductVariant: bindActionCreators(createProductVariant, dispatch),
  updateProduct: bindActionCreators(updateProduct, dispatch),
  removeProduct: bindActionCreators(removeProduct, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ListProducts);
