/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-responsive-modal";

import useForm from "react-valida-hook";
import {
  walletFundingValidatorSchema,
  formatErrs
} from "../../utils/validators";

import { ToastContainer } from "react-toastify";

export default ({
  fundWallet,
  onClose,
  isOpen,
  amount = 1,
  redirectUrl = "wallet"
}) => {
  const initialState = {
    amount,
    transaction_type_id: 1
  };

  let [formData, validation, validateForm, getData] = useForm(
    initialState,
    walletFundingValidatorSchema
  );
  const [isSubmitting, setSubmitting] = React.useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(getData());
    console.log(validateForm());
    if (!validateForm()) return;
    setSubmitting(true);
    await fundWallet(
      {
        redirect_url: redirectUrl,
        ...getData()
      },
      function(response) {
        setSubmitting(false);
        onClose();
      }
    );
    setSubmitting(false);
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ToastContainer />
      <section className="section-b-space py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-sm-10 mx-auto">
              <h3>Fund Wallet</h3>
              <div className="theme-card">
                <form
                  className="theme-form mx-auto"
                  method="post"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <input
                    type="hidden"
                    {...formData.transaction_type_id.input}
                  />
                  <div className="col-md-12">
                    <div className="form-row">
                      <label htmlFor="amount">Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount"
                        name="amount"
                        {...formData.amount.input}
                        min={1}
                      />
                      {validation.errors.amount && (
                        <p className="text text-danger">
                          {formatErrs(validation.errors.amount)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-row px-3 mt-2">
                    <button
                      type="submit"
                      className="btn btn-solid btn-primary btn-block mx-auto"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Processing..." : "Fund Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}
