import React, { Component, Fragment } from "react";
import { withAuthenticated } from "./HOCs/withAuthenticated";
import Breadcrumb from "./common/breadcrumb";
import { Navigation, Box, MessageSquare, Users } from "react-feather";
import CountUp from "react-countup";
import InventoryModal from "./common/inventoryModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAnalytics } from "../actions";

const NUM_MILISECS_IN_DAY = 8.64e7;

export class Dashboard extends Component {
  componentDidMount() {
    const { fetchAnalyticsData } = this.props;

    fetchAnalyticsData();
  }

  render() {
    const { user, analytics } = this.props;
    console.log(user);
    const canShowInventoryModal =
      user.profile &&
      user.profile.last_updated_item &&
      user.profile.role_label === "Customer" &&
      new Date(user.profile.last_updated_item).getTime() +
        NUM_MILISECS_IN_DAY * 7 <
        Date.now();

    return (
      <Fragment>
        <Breadcrumb title="Dashboard" parent="Dashboard" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="text-right">Analytics Overview</h3>
              <p className="text-right">
                Information about your business activity will be displayed below
                when available.
              </p>
            </div>
          </div>
        </div>

        {analytics.length ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-warning card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Navigation className="font-warning" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span className="m-0">Earnings</span>
                        <h3 className="mb-0">
                          ₦ <CountUp className="counter" end={0} />
                          <small> In wallet</small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden  widget-cards">
                  <div className="bg-secondary card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Box className="font-secondary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span className="m-0">Products</span>
                        <h3 className="mb-0">
                          <CountUp className="counter" end={0} />
                          <small>&nbsp; Published</small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-primary card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <MessageSquare className="font-primary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span className="m-0">Messages</span>
                        <h3 className="mb-0">
                          <CountUp className="counter" end={0} />
                          <small> Sent messages</small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-danger card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Users className="font-danger" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span className="m-0">New Orders</span>
                        <h3 className="mb-0">
                          <CountUp className="counter" end={0} />
                          <small>&nbsp; in Total</small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card text-center py-4">
            <img
              style={{ maxWidth: "50%", margin: "auto" }}
              src="https://i.ibb.co/vQGj8K0/undraw-create-f05x.png"
              alt="undraw-clean-up-ucm0"
            />
            <div className="w-50 mx-auto">
              <h4 className="">Analytics are not available.</h4>
              <p className="mb-3">
                We have no analytical data available at the moment.
              </p>
            </div>
          </div>
        )}
        {canShowInventoryModal && <InventoryModal isOpen />}
      </Fragment>
    );
  }
}
// javascript:void(0)

const mapStateToProps = (state) => {
  return {
    user: state.user,
    analytics: state.store.analytics,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAnalyticsData: bindActionCreators(fetchAnalytics, dispatch),
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
