import React from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import { Collapse } from "reactstrap";
import { InputField, LabelField } from "../../common/form";
import VariantField from "../../common/product/variantField";
import { variantValidatorSchema } from "../../../utils/validators";
import {
  ADD_FEATURE,
  EDIT_FEATURE,
  DELETE_FEATURE
} from "../../../constants/ActionTypes";

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_FEATURE:
      return {
        ...state,
        features: [
          ...state.features,
          {
            name: "",
            value: ""
          }
        ]
      };

    case EDIT_FEATURE:
      let featuresToUpdate = state.features;
      let { idx, field, value } = payload;
      featuresToUpdate[idx][field] = value;

      return {
        ...state,
        features: featuresToUpdate
      };

    case DELETE_FEATURE:
      return {
        ...state,
        features: [
          ...state.features.filter((feature, idx) => idx !== payload.idx)
        ]
      };

    default: {
      throw new Error("Invalid action type");
    }
  }
}

export default function EditProductVariantModal({
  isOpen,
  onClose,
  onSubmit,
  variant,
  variantIdx
}) {
  const initialState = {
    ...variant
  };

  const [formData, validation, validateForm, getData] = useForm(
    initialState,
    variantValidatorSchema
  );

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { features } = state;

  let handleSubmit = e => {
    e.preventDefault();
    if (!validateForm()) return;
    let variant = {
      ...getData(),
      features
    };

    return onSubmit({ variant, variantIdx });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <form className="section-b-space" onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <h3 className="text-center w-100 py-3">Edit a product variant.</h3>
          </div>
          <div className="col-12 py-3">
            <InputField
              validation={validation}
              formData={formData}
              label={"Variant Name"}
              field={"variant_name"}
            />
            <InputField
              validation={validation}
              formData={formData}
              label={"SKU"}
              field={"sku"}
            />
            <InputField
              validation={validation}
              formData={formData}
              label={"Color"}
              field={"color"}
            />
            <InputField
              validation={validation}
              formData={formData}
              label={"Size"}
              field={"size"}
            />

            <div className="offset-xl-3 offset-sm-4">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button type="button" onClick={onClose} className="btn btn-light">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
