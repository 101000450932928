import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import AuthorizeBankDetailChangeModal from "./AuthorizeBankDetailChangeModal";
import BankDetailForm from "./BankDetailForm";
import {
  fetchBanks,
  fetchBankDetail,
  updateBankDetail
} from "../../actions";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer } from 'react-toastify';

export class WalletTransactions extends Component {
  state = {
    isFetchingDetails: false,
    isAuthorizeBankDetailChangeModalOpen: false,
    formData: {
      account_name: this.props.bankDetail.account_name || '',
      account_number: this.props.bankDetail.account_number || '',
      bank_id: this.props.bankDetail.bank_id || '',
      password: '',
    }
  };

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleAuthorizationSubmit = this.handleAuthorizationSubmit.bind(this);
    this.closeAuthorizeBankDetailChangeModal = this.closeAuthorizeBankDetailChangeModal.bind(this);
  }

  async componentDidMount() {
    this.setState(prevState => ({ ...prevState, isFetchingDetails: true }));
    await Promise.all([
      this.props.fetchBanks(),
      this.props.fetchBankDetail()
    ]);
    this.setState(prevState => ({ ...prevState, isFetchingDetails: false }));
  }

  onSubmit({ account_number, account_name, bank_id }) {
    const formData = { ...this.state.formData };
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...formData,
          account_name,
          account_number,
          bank_id
        }
      };
    });
  }

  async handleAuthorizationSubmit(data) {
    const formData = { ...this.state.formData };
    await this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...formData,
          password: data.password
        }
      };
    });
    this.handleUpdateBankDetail();
  }

  async handleUpdateBankDetail() {
    if (!this.state.formData.password) return;
    await this.props.updateBankDetail(this.state.formData);
    this.closeAuthorizeBankDetailChangeModal();
  }

  closeAuthorizeBankDetailChangeModal() {
    this.setState({ isAuthorizeBankDetailChangeModalOpen: false });
  }

  render() {
    const { isAuthorizeBankDetailChangeModalOpen } = this.state;

    const {
      bankDetail,
      banks
    } = this.props;

    return (
      <Fragment>
        <ToastContainer />

        {isAuthorizeBankDetailChangeModalOpen && <AuthorizeBankDetailChangeModal
          isOpen={isAuthorizeBankDetailChangeModalOpen}
          onSubmit={this.handleAuthorizationSubmit}
          onClose={this.closeAuthorizeBankDetailChangeModal}
        />}

        <Breadcrumb title="Bank Detail" parent="Settings" />
        <div className="container-fluid">
        <div className="card">
            <div className="card-header">
                <p>By providing your bank detail, you can move funds from your wallet directly into the account in minutes.</p>
              </div>
              <div className="card-body vendor-table coupon-list-delete">
                {!this.state.isFetchingDetails ? (<BankDetailForm
                  onSubmit={(data) => {
                    console.log('Hello boy')
                    this.onSubmit(data);
                    this.setState({ isAuthorizeBankDetailChangeModalOpen: true })
                  }}
                  bankDetail={bankDetail}
                  options={{ banks }}
                />) : <h4>Please wait! Fetching account detail.</h4>}
              </div>
            </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    banks: state.banks,
    bankDetail: state.bankDetail
  };
};

const mapDispatchToProps = dispatch => ({
  fetchBanks: bindActionCreators(fetchBanks, dispatch),
  fetchBankDetail: bindActionCreators(fetchBankDetail, dispatch),
  updateBankDetail: bindActionCreators(updateBankDetail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletTransactions);
