import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import WithdrawalModal from "./WithdrawalModal";
import WalletFundingModal from "./WalletFundingModal";
import {
  fetchWalletTransactions,
  fundWallet,
  withdrawFromWallet,
} from "../../actions";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import asFormData from "json-form-data";
import { ToastContainer } from "react-toastify";

const TRANSACTION_PAGINATION_SIZE = 5;

const transformWalletTransactionData = (transactions) => {
  if (!transactions || !transactions.length) return transactions;

  return transactions.map(
    ({ created_at, amount, description, is_cleared, type }) => {
      return {
        Date: new Date(created_at).toDateString(),
        Description: description,
        Type: type,
        Amount: amount,
        Status: is_cleared ? "complete" : "pending",
      };
    }
  );
};

const calculateLedgerBalance = (balance = 0, transactions = []) => {
  return transactions
    .filter((transaction) => !transaction.is_cleared)
    .reduce((prev, current) => {
      if (current.type === "debit") {
        return prev - current.amount;
      }
      return prev + current.amount;
    }, balance);
};

export class WalletTransactions extends Component {
  state = {
    isWithdrawalModalOpen: false,
    isWalletFundingModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.onWithdraw = this.onWithdraw.bind(this);
    this.closeWithdrawalModal = this.closeWithdrawalModal.bind(this);
  }

  componentDidMount() {
    const { fetchWalletTransactions } = this.props;
    fetchWalletTransactions(
      {
        page: 1,
        limit: TRANSACTION_PAGINATION_SIZE,
      },
      function () {}
    );
  }

  async onWithdraw(data) {
    this.props.withdrawFromWallet(JSON.stringify(data));
    this.setState({ isWithdrawalModalOpen: false });
  }

  closeWithdrawalModal() {
    this.setState({ isWithdrawalModalOpen: false });
  }

  render() {
    const { isWithdrawalModalOpen } = this.state;

    const { balance, transactions } = this.props;

    let data = transformWalletTransactionData(transactions);

    return (
      <Fragment>
        <ToastContainer />

        {isWithdrawalModalOpen && (
          <WithdrawalModal
            isOpen={isWithdrawalModalOpen}
            balance={balance}
            onSubmit={this.onWithdraw}
            onClose={this.closeWithdrawalModal}
          />
        )}

        <WalletFundingModal
          onClose={() => this.setState({ isWalletFundingModalOpen: false })}
          fundWallet={this.props.fundWallet}
          isOpen={this.state.isWalletFundingModalOpen}
          redirectUrl="funding-success"
        />

        <Breadcrumb title="My Wallet" parent="Products" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h2>₦{this.props.balance}</h2>
              <p>
                Ledger balance:{" "}
                <span>₦{calculateLedgerBalance(balance, transactions)}</span>
              </p>

              <button
                onClick={() => this.setState({ isWithdrawalModalOpen: true })}
                disabled={
                  this.props.balance === null || this.props.balance === 0
                }
                className="btn btn-success"
                style={{ marginRight: "0.5em" }}
              >
                Withdraw
              </button>
              <button
                disabled={this.props.balance === null}
                className="btn primary"
                onClick={() =>
                  this.setState({ isWalletFundingModalOpen: true })
                }
              >
                Fund wallet
              </button>
            </div>
            <div className="card-body vendor-table coupon-list-delete">
              <p>
                You can update your bank details{" "}
                <Link to="/settings/bank">here</Link>.
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {data.length ? (
            <div className="card">
              <div className="card-header">
                <h5>Transactions</h5>
                <p>All transactions on your wallet.</p>
              </div>
              <div className="card-body vendor-table coupon-list-delete">
                <Datatable
                  rowEditable={false}
                  rowRemovable={false}
                  myData={data}
                  pageSize={TRANSACTION_PAGINATION_SIZE}
                  pagination={true}
                  className="-striped -highlight"
                />
              </div>
            </div>
          ) : (
            <div className="container-fluid">
              <div className="card">
                <div className="card-body vendor-table coupon-list-delete">
                  <Spinner type="grow" size="sm" color="success" />
                </div>
                <img
                  style={{ maxWidth: "100%", margin: "auto" }}
                  src="https://i.ibb.co/rfsrs1H/undraw-clean-up-ucm0.png"
                  alt="undraw-clean-up-ucm0"
                />
                <p className="text-center lead mb-4">
                  Fetching wallet transactions...
                </p>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    balance: state.user.profile ? state.user.profile.wallet_balance : null,
    transactions: state.wallet.transactions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchWalletTransactions: bindActionCreators(
    fetchWalletTransactions,
    dispatch
  ),
  withdrawFromWallet: bindActionCreators(withdrawFromWallet, dispatch),
  fundWallet: bindActionCreators(fundWallet, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletTransactions);
