import fetch from 'isomorphic-fetch';
import { getToken } from '../../utils';
const { API_URL } = process.env;

/**
 * Retrieves the bank details of the currently loggged in user
 */
function fetchBankDetail() {
  return fetch(`${API_URL}/api/v1/bank-details`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Updates the bank detail of the logged in user
 * @param {object} payload - an object containing data for the withdraw
 * @return {Promise<object>}
 */
function updateBankDetail(payload) {
  console.log(payload)
  return fetch(`${API_URL}/api/v1/bank-details`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload)
  });
}

export default {
  fetchBankDetail,
  updateBankDetail,
};
