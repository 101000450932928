import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Sidebar from "./common/sidebar_components/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ltr: true,
      divName: "RTL"
    };
  }
  ChangeRtl(divName) {
    if (divName === "RTL") {
      document.body.classList.add("rtl");
      this.setState({ divName: "LTR" });
    } else {
      document.body.classList.remove("rtl");
      this.setState({ divName: "RTL" });
    }
  }
  render() {
    let { profile } = this.props;
    profile = profile || {};
    return (
      <div>
        <div className="page-wrapper">
          <Header />
          <div className="page-body-wrapper">
            <Sidebar
              firstName={profile.first_name}
              adminRole={profile.role_label}
              balance={profile.wallet_balance}
            />
            <RightSidebar />
            <div className="page-body">{this.props.children}</div>
            <Footer />
          </div>
        </div>
        {/* <div
          className="btn-light custom-theme"
          onClick={() => this.ChangeRtl(this.state.divName)}
        >
          {this.state.divName}
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
