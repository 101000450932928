import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchStores, setStoreStatus } from "../../actions";
import { ToastContainer } from "react-toastify";
import Breadcrumb from "../common/breadcrumb";
import data from "../../assets/data/listVendor";
import Datatable from "../common/datatable";
import { withAuthenticated } from "../HOCs/withAuthenticated";

function transformStoresPayload(stores) {
  return stores.map((store) => ({
    id: store.id,
    name: store.store_name,
    status: store.is_activated_at ? "activated" : "inactive",
  }));
}
export class Stores extends Component {
  constructor(props) {
    super(props);
    this.actionHandler = this.actionHandler.bind(this);
  }

  componentDidMount() {
    this.props.fetchStores();
  }

  actionHandler(store, { type }) {
    let status = {
      activate: 1,
      deactivate: 0,
    };

    this.props.setStoreStatus(store, status[type]);
  }

  render() {
    const { stores } = this.props;
    return (
      <Fragment>
        <ToastContainer />
        <Breadcrumb title="Stores" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Stores</h5>
            </div>
            <div className="card-body vendor-table coupon-list-delete">
              {stores.length ? (
                <Datatable
                  multiSelectOption={false}
                  myData={transformStoresPayload(stores)}
                  pageSize={10}
                  pagination={true}
                  class="-striped -highlight"
                  rowsRemovable={false}
                  rowsEditable={false}
                  actions={(store) => [
                    store.status === "inactive"
                      ? {
                          label: "Activate",
                          handler: (store) =>
                            this.actionHandler(store, { type: "activate" }),
                        }
                      : {
                          label: "Deactivate",
                          handler: (store) =>
                            this.actionHandler(store, { type: "deactivate" }),
                        },
                  ]}
                />
              ) : (
                <div class="col-lg-6 mx-auto">
                  <img
                    style={{ maxWidth: "100%", margin: "auto" }}
                    src="https://i.ibb.co/g46d7Qz/undraw-web-shopping-dd4l.png"
                    alt="undraw-clean-up-ucm0"
                  />
                  <p class="text-center lead mb-4">No stores available yet</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    stores: state.store.stores,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStoreStatus: bindActionCreators(setStoreStatus, dispatch),
  fetchStores: bindActionCreators(fetchStores, dispatch),
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(Stores)
);
