import React from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import { InputField } from "../common/form";
import { orderNotificationsValidatorSchema } from "../../utils/validators";
import { toast, ToastContainer } from "react-toastify";
import Countdown from "react-countdown";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function OrderModal({
  isOpen,
  onSubmit,
  isSubmitting,
  onClose,
  shippingCost,
  order,
  onDeclineOrder,
  onDeliverOrder
}) {
  const initialState = {
    shipping_fee: ""
  };

  let [formData, validation, validateForm, getData] = useForm(
    initialState,
    orderNotificationsValidatorSchema
  );

  let handleSubmit = e => {
    e.preventDefault();
    if (!validateForm()) return;
    let data = {
      ...getData()
    };

    if (data.shipping_fee <= 0)
      return toast.error("Your shipping fee must be greater than 0");

    return onSubmit(data);
  };

  const timeDiff =
    new Date(order.expiring_time).getTime() - new Date().getTime();

  const provinceLabel = order.order_notification.order_address.length
    ? order.order_notification.order_address[0].province.province_label
    : "";

  const stateLabel = order.order_notification.order_address.length
    ? order.order_notification.order_address[0].state.state_label
    : "";

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal open={isOpen} onClose={onClose}>
        <form className="section-b-space bg-light" onSubmit={handleSubmit}>
          <div className="container">
            {!order.shipping_cost && !order.declined_at ? (
              <div className="row">
                <h3 className="text-center w-100 pt-3">
                  What to do with this order?
                </h3>
                <p className="text-muted text-center w-100 pb-2">
                  Can you supply to {provinceLabel}, {stateLabel}? Add a
                  shipping fee or decline if you can't deliver
                </p>
                <div className="col-12 py-3 text-dark">
                  <InputField
                    validation={validation}
                    formData={formData}
                    label={"Shipping fee"}
                    field={"shipping_fee"}
                    type={"number"}
                  />
                  <div className="offset-xl-3 offset-sm-4">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-dark"
                    >
                      Add shipping fee
                    </button>
                    <button
                      type="button"
                      onClick={() => onDeclineOrder(order)}
                      className="btn btn-default"
                    >
                      Decline order
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center my-3">Order Information</h3>
                </div>
                {order &&
                order.declined_at === null &&
                order.is_paid_at !== null &&
                timeDiff > 0 ? (
                  <div className="col-md-5 mt-3 mb-2">
                    {!order.delivered_at && !order.declined_at && (
                      <>
                        <h5 className="text-center mb-3">
                          Time left to deliver order
                        </h5>

                        <div
                          className="mx-auto mb-2"
                          style={{
                            width: 100
                          }}
                        >
                          <CircularProgressbar
                            value={
                              100 -
                              (
                                (timeDiff /
                                  (new Date(order.expiring_time) -
                                    new Date(order.is_paid_at))) *
                                100
                              ).toFixed(2)
                            }
                            text={`${Math.ceil(
                              100 -
                                (timeDiff /
                                  (new Date(order.expiring_time) -
                                    new Date(order.is_paid_at))) *
                                  100
                            ).toFixed(1)}%`}
                          />
                        </div>
                        <p className="mb-4 text-center text-muted lead">
                          <Countdown
                            date={
                              Date.now() +
                              (new Date(order.expiring_time).getTime() -
                                Date.now())
                            }
                            renderer={({ days, hours, minutes, seconds }) => {
                              return `${days ? days + "D" : ""} ${
                                hours ? hours + "H" : ""
                              } ${minutes ? minutes + "M" : ""} ${seconds}S`;
                            }}
                          />
                        </p>
                      </>
                    )}
                    {order.is_paid_at ? (
                      <>
                        {!order.delivered_at && !order.declined_at && (
                          <>
                            <button
                              onClick={() => onDeliverOrder(order)}
                              type="submit"
                              disabled={false}
                              className="btn btn-dark"
                            >
                              Order was delivered
                            </button>
                            <button
                              onClick={() => onDeclineOrder(order)}
                              type="submit"
                              disabled={false}
                              className="btn btn-link text-danger"
                            >
                              Decline order
                            </button>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="col-md-5 mt-3 mb-2">
                    {order.is_paid_at ? (
                      <h5 className="text-center mb-3">
                        Order delivery time has been exceeded
                      </h5>
                    ) : (
                      !order.declined_at && (
                        <h5 className="text-center mb-3">
                          You may deliver to this buyer once payment is made
                        </h5>
                      )
                    )}
                  </div>
                )}
                {order &&
                order.hasOwnProperty("order_notification") &&
                "buyer_details" in order.order_notification ? (
                  <div className="col-md-7">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title px-3">Buyer Details</h5>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item text-dark">
                            <div className="row">
                              <div className="col-5 text-muted">Name</div>
                              <div className="col-md-7">
                                <b>
                                  {
                                    order.order_notification.buyer_details
                                      .profile.first_name
                                  }
                                  &nbsp;
                                  {
                                    order.order_notification.buyer_details
                                      .profile.last_name
                                  }
                                </b>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item text-dark">
                            <div className="row">
                              <div className="col-md-5 text-muted">Phone</div>
                              <div className="col-md-7">
                                <b>
                                  {
                                    order.order_notification.buyer_details
                                      .phone_number
                                  }
                                </b>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item text-dark">
                            <div className="row">
                              <div className="col-md-5 text-muted">Address</div>
                              <div className="col-md-7">
                                <b>
                                  {order.order_notification.order_address.length
                                    ? order.order_notification.order_address[0]
                                        .address
                                    : ""}
                                  , &nbsp;
                                  {stateLabel}
                                </b>
                              </div>
                            </div>
                          </li>
                          {/* <li className="list-group-item text-dark">
                            <div className="row">
                              <div className="col-5 text-muted">Country</div>
                              <div className="col-7">
                                <b>
                                  {
                                    order.order_notification.order_address[0]
                                      .country_code.name
                                  }
                                </b>
                              </div>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}

                {order && order.hasOwnProperty("order_notification") ? (
                  <div className="col-md-7 offset-md-5">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title px-3">Products</h5>
                        <ul className="list-group list-group-flush">
                          {order.order_notification.order_items.map(
                            (
                              {
                                product_name,
                                color,
                                size,
                                main_product_images,
                                pivot
                              },
                              key
                            ) => {
                              return (
                                <li
                                  key={key}
                                  className="list-group-item text-dark"
                                >
                                  <div className="row">
                                    <div className="col-2">
                                      {main_product_images.length > 0 ? (
                                        <img
                                          className="mr-2"
                                          src={main_product_images[0].image_url}
                                          width={50}
                                          alt={product_name}
                                        />
                                      ) : null}
                                    </div>
                                    <div className="offset-1 col-5">
                                      <span className="">{product_name}</span>
                                    </div>
                                    <div className="col-4">
                                      <ul className="list list-unstyled">
                                        <li className="mb-2 d-block">
                                          <b>{`${pivot.qty} ${
                                            pivot.qty === 1 ? "piece" : "pieces"
                                          }`}</b>
                                        </li>
                                        {pivot.size && pivot.size.length ? (
                                          <li className="mb-2 d-block">
                                            <b>Size:</b>
                                            <span className="ml-2 badge badge-primary">
                                              {pivot.size}
                                            </span>
                                          </li>
                                        ) : null}
                                        {pivot.color && pivot.color.length ? (
                                          <li className="mb-2 d-block">
                                            <b>Color:</b>
                                            <span
                                              className="ml-2 d-block"
                                              style={{
                                                width: 25,
                                                height: 25,
                                                background: pivot.color,
                                                borderRadius: "100%"
                                              }}
                                            ></span>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-center">Loading..</p>
                )}
              </div>
            )}
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
}
