import * as types from "../constants/ActionTypes.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const registerUserAction = user => ({
  type: types.REGISTER_USER,
  user
});

export const registerUser = (user, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .registerUser(user)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(registerUserAction(user));
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

const loginUserAction = user => ({
  type: types.LOGIN_USER,
  user
});

export const loginUser = (user, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .loginUser(user)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success("Successfully logged in");

        dispatch(loginUserAction(user));
        localStorage.setItem("token", response.result.token);
        localStorage.setItem("refreshToken", response.result.refreshToken);
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const generatePasswordReset = (payload, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .generatePasswordReset(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => callback(response))
      .catch(api.errorHandler);
  };
};

export const resetPassword = (payload, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .resetPassword(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => callback(response))
      .catch(api.errorHandler);
  };
};
