import React from "react";

export function ImageField({ images, onChange, onSubmit }) {
  return (
    <div className="add-product mb-3">
      <div className="col-xl-3 xl-50 col-sm-6 col-3">
        <ul className="file-upload-product">
          {images.map((img, i) => {
            return (
              <li key={i}>
                <div className="box-input-file">
                  <input
                    className="upload"
                    type="file"
                    onChange={e => onChange(e, i)}
                  />
                  <img alt="" src={img.image_url} style={{ width: 55 }} />
                  <a href="/" id="result1" onClick={e => onSubmit(e.target.id)}>
                    {""}
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
