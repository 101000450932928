import React from 'react';
import { formatErrs } from '../../../utils/validators';
import { LabelField } from './labelField';

export function InputField({
  validation,
  field,
  formData,
  label,
  placeholder,
  type = 'text',
  ...rest
}) {
  return (
    <div className="form-group row">
      {label && <LabelField label={label} />}
      <div className="col-xl-8 col-md-7">
        <input
          className="form-control"
          id={`${field}`}
          type={type}
          required=""
          placeholder={placeholder}
          {...formData[field]['input']}
          {...rest}
        />
        {validation.errors[field].length ? (
          <p className="text pr-2 pt-1 text-danger">
            {formatErrs(validation.errors[field])}
          </p>
        ) : null}
      </div>
    </div>
  );
}
