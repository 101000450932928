import fetch from "isomorphic-fetch";
import { getToken } from "../../utils";
const { API_URL } = process.env;
let qs = require("qs");

/**
 * Creates a store http request.
 * @param {object} store
 * @return {Promise<object>}
 */
function createStore(store) {
  return fetch(`${API_URL}/api/v1/Store/CreateStore`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(store)
  });
}

/**
 * Creates a store.
 * @param {object} payload
 * @param {number} storeId
 *
 * @return {Promise<object>}
 */
function createStoreProduct(storeId, payload) {
  return fetch(`${API_URL}/api/v1/Store/${storeId}/AddProduct/`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      Authorization: `Bearer ${getToken()}`
    },
    body: payload
  });
}

/**
 * Creates a store.
 * @param {object} payload
 * @param {number} storeId
 *
 * @return {Promise<object>}
 */
function createProductVariant({ productId, variant }) {
  return fetch(`${API_URL}/api/v1/Product/${productId}/AddVariant`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      Authorization: `Bearer ${getToken()}`
    },
    body: variant
  });
}

/**
 * Fetches stores.
 * @return {Promise<object>}
 */
function fetchStores() {
  return fetch(`${API_URL}/api/v1/Store/AllStores`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates http request for fetching store products.
 * @return {Promise<object>}
 */
function fetchStoreProducts({ page = 1, limit = 20 }) {
  let queryParams = qs.stringify({ page, limit });

  return fetch(`${API_URL}/api/v1/Store/Product?${queryParams}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates http request for fetching product categories.
 * @return {Promise<object>}
 */
function fetchProductCategories() {
  return fetch(`${API_URL}/api/v1/Product/categories`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates http request for fetching product categories.
 * @return {Promise<object>}
 */
function fetchProductSubCategories(id) {
  return fetch(`${API_URL}/api/v1/Product/ProductSubCategory/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates http request for fetching analytics.
 * @return {Promise<object>}
 */
function fetchAnalytics(storeId) {
  return fetch(`${API_URL}/api/v1/analytics`, {
    method: "GET",
    body: { storeId },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates a store http request.
 * @param {object} store
 * @return {Promise<object>}
 */
function updateProduct(productId, payload) {
  return fetch(`${API_URL}/api/v1/Store/${productId}/EditProduct`, {
    method: "PUT",
    headers: {
      Accept: "*/*",
      Authorization: `Bearer ${getToken()}`
    },
    body: payload
  });
}

/**
 * Creates a store http request.
 * @param {object} store
 * @return {Promise<object>}
 */
function removeProduct(productId) {
  return fetch(`${API_URL}/api/v1/Product/${productId}/DeleteProduct`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Sets the activation status of a store.
 * @param {number} storeId
 * @param {number} status
 *
 * @return {Promise<object>}
 */
function setStoreStatus(storeId, status) {
  let queryParams = qs.stringify({ is_active: status });
  return fetch(
    `${API_URL}/api/v1/Store/ActivateStore/${storeId}?${queryParams}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }
  );
}

/**
 * Adds a shipping fee for an order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function addOrderShippingFee(orderId, payload) {
  return fetch(`${API_URL}/api/v1/orders/${orderId}/add-shipping-cost`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Adds a shipping fee for an order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function updateOrder(orderId, payload) {
  return fetch(`${API_URL}/api/v1/orders/${orderId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Fetches a single order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function getSingleOrder(orderId) {
  return fetch(`${API_URL}/api/v1/orders/${orderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Fetches a single order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function confirmSellerDelivery(orderId) {
  return fetch(`${API_URL}/api/v1/order/delivered/${orderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

export default {
  confirmSellerDelivery,
  addOrderShippingFee,
  createStore,
  createStoreProduct,
  createProductVariant,
  fetchAnalytics,
  fetchStoreProducts,
  fetchProductCategories,
  fetchProductSubCategories,
  fetchStores,
  getSingleOrder,
  updateProduct,
  updateOrder,
  removeProduct,
  setStoreStatus
};
