import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SearchHeader from "./searchHeader";
import Notification from "./notification";
import User_menu from "./user-menu";
import Language from "./language";
import Bar from "../spinners/bar";
import Container from "../spinners/container";
import "../spinners/spinner.css";
import Spinner from "../spinners/spinner";
import {
  AlignLeft,
  Maximize2,
  Bell,
  MessageSquare,
  MoreHorizontal
} from "react-feather";

//images
import Logo from "../../../assets/images/dashboard/logo.svg";

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      rightSidebar: true,
      navMenus: false
    };
  }
  toggle() {
    this.setState(prevState => ({
      navMenus: !prevState.navMenus
    }));
  }
  showRightSidebar = () => {
    if (this.state.rightSidebar) {
      this.setState({ rightSidebar: false });
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      this.setState({ rightSidebar: true });
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  };
  goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };
  openCloseSidebar = () => {
    if (this.state.sidebar) {
      this.setState({ sidebar: false });
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    } else {
      this.setState({ sidebar: true });
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    }
  };
  render() {
    const { isLoading, user } = this.props;
    return (
      <Fragment>
        {/* open */}
        <div className="page-main-header">
          <div className="main-header-right row">
            <div className="main-header-left d-lg-none">
              <div className="logo-wrapper">
                <a href={`${process.env.PUBLIC_URL}`}>
                  <img className="blur-up lazyloaded" src={Logo} alt="" />
                </a>
              </div>
            </div>
            <div className="mobile-sidebar">
              <div className="media-body text-right switch-sm">
                <label className="switch">
                  <a onClick={this.openCloseSidebar}>
                    <AlignLeft />
                  </a>
                </label>
              </div>
            </div>
            <div className="nav-right col">
              <ul
                className={"nav-menus " + (this.state.navMenus ? "open" : "")}
              >
                <li>
                  <a onClick={this.goFull} className="text-dark" href="#!">
                    <Maximize2 />
                  </a>
                </li>
                {/* <li className="onhover-dropdown">
                  <a className="txt-dark" href="#">
                    <h6>EN</h6>
                  </a>
                  <Language />
                </li> */}

                {user.profile ? (
                  <li className="onhover-dropdown">
                    <Bell />
                    {user.profile.total_unread_messages > 0 ? (
                      <>
                        <span className="badge badge-pill badge-primary pull-right notification-badge"></span>
                        <span className="dot"></span>
                      </>
                    ) : null}
                    <Notification
                      messagesCount={user.profile.total_unread_messages}
                    />
                  </li>
                ) : null}
                <User_menu />
              </ul>
              <div
                className="d-lg-none mobile-toggle pull-right"
                onClick={() => this.toggle()}
              >
                <MoreHorizontal />
              </div>
            </div>
          </div>
        </div>
        <Container isFinished={!isLoading} animationDuration={isLoading}>
          <Bar progress={100} animationDuration={100} />
          <Spinner />
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.app.isLoading,
    user: state.user
  };
};

export default connect(mapStateToProps, null)(Header);
