import React from "react";

export function LabelField({ label, required = true }) {
  return (
    <label className="col-xl-3 col-md-4 d-flex align-items-center">
      <div>
        {required ? <b className="text-danger">*</b> : null} {label}
      </div>
    </label>
  );
}
