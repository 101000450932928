import React from "react";
import { Card } from "reactstrap";

export default function VariantField({ name, value, onDelete, onEdit, idx }) {
  return (
    <Card style={{ backgroundColor: "#f5f5f5" }}>
      <div>
        <a
          href="#/"
          onClick={e => onDelete(e, idx)}
          className="btn btn-xs pull-right"
        >
          <i className="fa fa-close fa-2x"></i>
        </a>
      </div>
      <div className="form-group mx-auto row">
        <div className="col-6">
          <label className="font-weight-bold">Feature name</label>
          <input
            className="form-control"
            value={name}
            onChange={e => onEdit(e, { idx, field: "name" })}
            required=""
            placeholder=""
          />
        </div>
        <div className="col-6">
          <label className="font-weight-bold">Value</label>
          <input
            className="form-control"
            value={value}
            onChange={e => onEdit(e, { idx, field: "value" })}
            required=""
            placeholder=""
          />
        </div>
      </div>
    </Card>
  );
}
