import fetch from 'isomorphic-fetch';
import { getToken } from '../../utils';
const { API_URL } = process.env;

/**
 * Retrieves profile info for a user.
 */
function fetchUsers() {
  return fetch(`${API_URL}/api/v1/Users`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Retrieves profile info for a user.
 */
function getUserProfile() {
  return fetch(`${API_URL}/api/v1/Profile/Info`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Retrieves order notifications for a user.
 */
function fetchOrderNotifications({ role_label }) {
  const url =
    role_label.toLowerCase() === 'customer'
      ? 'api/v1/seller/orders'
      : 'api/v1/admin/orders/';

  return fetch(`${API_URL}/${url}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Retrieves all messages in chat.
 */
function fetchChatMessages(orderId) {
  return fetch(`${API_URL}/api/v1/chat/${orderId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Retrieves all messages in chat.
 */
function sendChatMessage(payload) {
  return fetch(`${API_URL}/api/v1/chat/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Retrieves all messages in chat.
 */
function setBanStatus(userId, payload) {
  return fetch(`${API_URL}/api/v1/users/${userId}/ban`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  });
}

export default {
  fetchUsers,
  fetchOrderNotifications,
  getUserProfile,
  fetchChatMessages,
  sendChatMessage,
  setBanStatus,
};
