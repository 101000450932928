import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import {
  InputField,
  SelectField
} from "../common/form";
import { bankDetailValidatorSchema } from "../../utils/validators";
import { prepareSelectOptions } from "../../utils";

export default function WithdrawalModal({ onSubmit, bankDetail, options }) {
  const [initialFormState, setInitialFormState] = useState({
    account_name: bankDetail.account_name || '',
    account_number: bankDetail.account_number || '',
    bank_id: bankDetail.bank_id || '',
  });

  useEffect(() => {
    setInitialFormState(bankDetail);
  }, [bankDetail]);

  let [formData, validation, validateForm, getData] = useForm(
    initialFormState,
    bankDetailValidatorSchema
  );

  let handleSubmit = e => {
    e.preventDefault();
    if (!validateForm()) return;

    let data = {
      ...getData(),
    };

    return onSubmit(data);
  };

  return (
      <form className="section-b-space px-3 py-4" onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <label>Account Name</label>
              <InputField
                validation={validation}
                formData={formData}
                // label={"Amount"}
                field={"account_name"}
                type={"input"}
                min={0}
                style={{ width: '100%', maxWidth: '100%' }}
              />
            </div>
            
            <div className="col-12">
              <label>Account Number</label>
              <InputField
                validation={validation}
                formData={formData}
                // label={"Amount"}
                field={"account_number"}
                type={"input"}
                min={0}
                style={{ width: '100%', maxWidth: '100%' }}
              />
            </div>
            
            <div className="col-12">
              <label>Bank</label>
              <SelectField
                  validation={validation}
                  formData={formData}
                  field={"bank_id"}
                  // label="Category"
                  options={prepareSelectOptions({ items: options.banks, label: 'name', value: 'id' })}
                />
            </div>

            <div style={{ width: 'fit-content', marginRight: 'auto' }} className="col-12">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
  );
}
