import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchUsers, setBanStatus } from "../../actions";
import { ToastContainer } from "react-toastify";
import { withAuthenticated } from "../HOCs/withAuthenticated";

export class ListUsers extends Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  actionHandler(user, { type }) {
    this.props.setBanStatus(user.id, type, () => {
      window.location.reload();
    });
  }

  render() {
    const { users } = this.props;

    const transformedUsers = users.map(
      ({ id, email, phone_number, banned_at, is_activated_at }, idx) => {
        return {
          id,
          email,
          phone: phone_number,
          banned_at: banned_at ? "yes" : "no",
          is_activated_at: is_activated_at
            ? new Date(is_activated_at).toLocaleDateString()
            : is_activated_at
        };
      }
    );

    return (
      <Fragment>
        <ToastContainer />
        <Breadcrumb title="User List" parent="Users" />
        <div className="container-fluid">
          {users.length ? (
            <div className="card">
              <div className="card-header">
                <h5>All Users</h5>
              </div>
              <div className="card-body">
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table user-list order-table coupon-list-delete"
                >
                  {users.length ? (
                    <Datatable
                      multiSelectOption={false}
                      rowsEditable={false}
                      rowsRemovable={false}
                      myData={transformedUsers}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                      actions={({ banned_at }) => [
                        banned_at === "no"
                          ? {
                              label: "Ban",
                              handler: user =>
                                this.actionHandler(user, { type: "ban" })
                            }
                          : {
                              label: "Unban",
                              handler: user =>
                                this.actionHandler(user, { type: "unban" })
                            }
                      ]}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">Loading...</div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.user.users
  };
};

const mapDispatchToProps = dispatch => ({
  setBanStatus: bindActionCreators(setBanStatus, dispatch),
  fetchUsers: bindActionCreators(fetchUsers, dispatch)
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(ListUsers)
);
