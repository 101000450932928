import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import FancyRoute from "./components/common/router_loader/fancyRoute";
import "./index.scss";
import store from "./store";
import App from "./components/app";
import { ScrollContext } from "react-router-scroll-4";

// Components
import Dashboard from "./components/dashboard";

// Products physical
// import Category from "./components/products/physical/category";
// import Sub_category from "./components/products/physical/sub-category";
import ListProducts from "./components/products/list-products";
import AddProduct from "./components/products/physical/add-product";
import Product from "./components/products/physical/product-detail";

//Product Digital
// import Digital_category from "./components/products/digital/digital-category";
// import Digital_sub_category from "./components/products/digital/digital-sub-category";
// import Digital_pro_list from "./components/products/digital/digital-pro-list";
// import Digital_add_pro from "./components/products/digital/digital-add-pro";

//Sales
import Orders from "./components/sales/orders";
import Transactions_sales from "./components/sales/transactions-sales";
//Coupons
// import ListCoupons from "./components/coupons/list-coupons";
// import Create_coupons from "./components/coupons/create-coupons";

//Pages
// import ListPages from "./components/pages/list-page";
// import Create_page from "./components/pages/create-page";
// import Media from "./components/media/media";
// import List_menu from "./components/menus/list-menu";
// import Create_menu from "./components/menus/create-menu";
import ListUsers from "./components/users/list-users";
// import Create_user from "./components/users/create-user";
import List_vendors from "./components/vendors/list-vendors";
import Create_vendors from "./components/vendors/create.vendors";
import Stores from "./components/stores";
import CreateStore from "./components/stores/create-store";
// import Translations from "./components/localization/translations";
// import Rates from "./components/localization/rates";
// import Taxes from "./components/localization/taxes";
// import Profile from "./components/settings/profile";
// import Reports from "./components/reports/report";
// import Invoice from "./components/invoice";
// import Datatable from "./components/common/datatable";
import Login from "./components/auth/login";

// Wallet
import Wallet from './components/wallet/transactions';

// Bank Account
import BankDetail from './components/bankDetail/BankDetail';

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter forceRefresh basename={"/"}>
          <ScrollContext>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/auth/login`}
                component={Login}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/logout`}
                component={(props) => {
                  React.useEffect(() => {
                    localStorage.removeItem("token");
                    props.history.push("/auth/login");
                  }, [props]);

                  return null;
                }}
              />

              <App>
                <FancyRoute>
                  <Route
                    path={`${process.env.PUBLIC_URL}/`}
                    render={({ match: { url } }) => (
                      <>
                        <Route path={`${url}/`} component={Dashboard} />
                        <Route
                          path={`${process.env.PUBLIC_URL}/dashboard`}
                          component={Dashboard}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/vendors/list_vendors`}
                          component={List_vendors}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/vendors/create-vendors`}
                          component={Create_vendors}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/stores`}
                          component={Stores}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/stores/create`}
                          component={CreateStore}
                        />

                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/products/`}
                          component={ListProducts}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/products/add-product`}
                          component={AddProduct}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/sales/all-orders`}
                          component={Orders}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/sales/orders`}
                          component={Orders}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/wallet`}
                          component={Wallet}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/settings/bank`}
                          component={BankDetail}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/sales/transactions`}
                          component={Transactions_sales}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/users/list-users`}
                          component={ListUsers}
                        />
                        {/* <Route path={`${process.env.PUBLIC_URL}/products/physical/category`} component={Category} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/sub-category`} component={Sub_category} /> */}

                        {/* <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/products/:product`}
                        component={Product}
                      /> */}
                      </>
                    )}
                  />

                  {/* <Route path={`${process.env.PUBLIC_URL}/products/physical/category`} component={Category} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/sub-category`} component={Sub_category} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/product-list`} component={Product_list} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/product-detail`} component={Product_detail} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/add-product`} component={Add_product} />

                            <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-category`} component={Digital_category} />
                            <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-sub-category`} component={Digital_sub_category} />
                            <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-product-list`} component={Digital_pro_list} />
                            <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-add-product`} component={Digital_add_pro} />

                            <Route path={`${process.env.PUBLIC_URL}/coupons/list-coupons`} component={ListCoupons} />
                            <Route path={`${process.env.PUBLIC_URL}/coupons/create-coupons`} component={Create_coupons} />

                            <Route path={`${process.env.PUBLIC_URL}/pages/list-page`} component={ListPages} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/create-page`} component={Create_page} />

                            <Route path={`${process.env.PUBLIC_URL}/media`} component={Media} />

                            <Route path={`${process.env.PUBLIC_URL}/menus/list-menu`} component={List_menu} />
                            <Route path={`${process.env.PUBLIC_URL}/menus/create-menu`} component={Create_menu} />

                            <Route path={`${process.env.PUBLIC_URL}/users/create-user`} component={Create_user} /> */}

                  {/* <Route path={`${process.env.PUBLIC_URL}/localization/transactions`} component={Translations} />
                            <Route path={`${process.env.PUBLIC_URL}/localization/currency-rates`} component={Rates} />
                            <Route path={`${process.env.PUBLIC_URL}/localization/taxes`} component={Taxes} />

                            <Route path={`${process.env.PUBLIC_URL}/reports/report`} component={Reports} />

                            <Route path={`${process.env.PUBLIC_URL}/settings/profile`} component={Profile} />

                            <Route path={`${process.env.PUBLIC_URL}/invoice`} component={Invoice} />

                            <Route path={`${process.env.PUBLIC_URL}/data-table`} component={Datatable} /> */}
                </FancyRoute>
              </App>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
