import React, { useState } from "react";
import {
  Button,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import asFormData from "json-form-data";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { fetchStoreProducts, updateProduct } from "../../actions";
import { ToastContainer } from "react-toastify";

const CarouselItem = ({
  id,
  product_name,
  price,
  stock,
  main_product_images,
  isSaving = false,
  onSave
}) => {
  const [quantity, setQuantity] = React.useState(stock);

  return (
    <div className="container">
      <div className="row bg-white">
        <div className="col-md-12">
          <Card>
            <CardImg
              src={
                main_product_images.length
                  ? main_product_images[0].image_url
                  : "https://via.placeholder.com/150"
              }
              alt={`Image for ${product_name}`}
            />

            <CardBody>
              <CardTitle>
                <h3>{product_name}</h3>
              </CardTitle>
              <CardSubtitle className="mb-3">NGN {price}</CardSubtitle>
              <Label>Update quantity available</Label>
              <InputGroup className="mb-2">
                <InputGroupAddon
                  onClick={() => {
                    if (!quantity) return;
                    setQuantity(quantity - 1);
                  }}
                  addonType="prepend"
                >
                  &minus;
                </InputGroupAddon>
                <Input
                  placeholder="Amount"
                  min={0}
                  max={100}
                  onChange={({ target: { value } }) => setQuantity(value)}
                  value={quantity}
                  type="number"
                  step="1"
                  style={{ textAlign: "center" }}
                />
                <InputGroupAddon
                  onClick={() => {
                    setQuantity(quantity + 1);
                  }}
                  addonType="append"
                >
                  &#43;
                </InputGroupAddon>
              </InputGroup>
              <Button
                onClick={() => onSave({ id, stock: quantity })}
                disabled={isSaving}
                block
              >
                Save
              </Button>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

const InventoryCarousel = ({ products = [], isSavingItemId, onItemSave }) => {
  const carouselItems = products
    ? products.map((product, key) => (
        <CarouselItem
          {...product}
          onSave={onItemSave}
          isSaving={isSavingItemId === product.id}
          key={key}
        />
      ))
    : [];

  return (
    <Carousel
      showArrows
      showIndicators={false}
      centerMode
      centerSlidePercentage={80}
    >
      {carouselItems}
    </Carousel>
  );
};

function InventoryModal({
  isOpen = false,
  products,
  fetchStoreProducts,
  updateProduct
}) {
  const [modal, setModal] = React.useState(isOpen);
  const [isSavingItemId, setIsSavingItemId] = React.useState(null);

  React.useEffect(() => {
    fetchStoreProducts({ page: 1, limit: 25 });
  }, [fetchStoreProducts]);

  const toggle = () => setModal(!modal);
  const handleItemSave = ({ id, stock }) => {
    setIsSavingItemId(id);
    updateProduct(id, asFormData({ stock }), () => {
      setIsSavingItemId(null);
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update Product inventory</ModalHeader>
        <ModalBody>
          <InventoryCarousel
            products={products}
            isSavingItemId={isSavingItemId}
            onItemSave={handleItemSave}
          />
        </ModalBody>
      </Modal>
      <ToastContainer />
    </div>
  );
}
const mapStateToProps = state => {
  return {
    products: state.store.products
  };
};

const mapDispatchToProps = dispatch => ({
  fetchStoreProducts: bindActionCreators(fetchStoreProducts, dispatch),
  updateProduct: bindActionCreators(updateProduct, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryModal);
