import * as React from "react";
import store from "../../store";
import { getUserProfile } from "../../actions";
import { getToken } from "../../utils";

export function withAuthenticated(WrappedComponent, redirect = true) {
  class AuthenticatedComp extends React.Component {
    componentDidMount() {
      const redirectToLogin = () => {
        if (!redirect) return;
        this.props.history.push("/auth/login");
      };

      if (!getToken()) return redirectToLogin();
      store.dispatch(getUserProfile(redirectToLogin));
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return AuthenticatedComp;
}
