import React from 'react';
import { formatErrs } from '../../../utils/validators';
import { LabelField } from './labelField';

export function RadioField({
  validation,
  field,
  formData,
  label,
  options,
  value,
}) {
  return (
    <div className="row">
      <LabelField label={label} />

      <div className="form-group col-xl-8 col-md-7 m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0">
        {options.map((option, key) => {
          return (
            <label className="d-block mb-0 mr-3" key={key}>
              <input
                className="radio_animated"
                type="radio"
                name={field}
                {...formData[field].input}
                value={option.value}
                defaultChecked={option.value === value}
              />
              {option.label}
            </label>
          );
        })}
      </div>
      {validation.errors[field] && (
        <p className="text text-danger">
          {formatErrs(validation.errors[field])}
        </p>
      )}
    </div>
  );
}
