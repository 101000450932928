import * as types from "../constants/ActionTypes.js";
import { toast } from "react-toastify";
import { toggleAppState } from "./app.actions";
import "react-toastify/dist/ReactToastify.min.css";

export const fetchWalletTransactionsAction = transactions => ({
  type: types.FETCH_WALLET_TRANSACTIONS,
  payload: transactions
});

const updateWalletBalance = (balance) => ({
  type: types.UPDATE_WALLET_BALANCE,
  payload: balance
});

export const fetchWalletTransactions = () => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchWalletTransactions()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchWalletTransactionsAction(response.result));
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

export const fundWallet = (payload, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    return api
      .fundWallet(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        window.location.href = response.authorization_url;
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const withdrawFromWallet = (data) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .withdrawFromWallet(data)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success("Withdrawal successful");
        const { wallet: { transactions }, user: { profile } } = getState();
        dispatch(updateWalletBalance(profile.wallet_balance - response.result.amount));
        dispatch(fetchWalletTransactionsAction([...transactions, response.result]));
        dispatch(toggleAppState());
      })
      .catch((error) => {
        toast.success(error.response ? error.response.message : error.message);
        api.errorHandler(error);
        dispatch(toggleAppState());
      });
  };
};
