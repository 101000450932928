import React, { Component, Fragment } from "react";
import LoginTabset from "./loginTabset";
import { ArrowLeft, Sliders } from "react-feather";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import stats from "../../assets/images/dashboard/stats.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";

export class Login extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false
    };
    return (
      <Fragment>
        <ToastContainer />
        <div className="page-wrapper">
          <div className="authentication-box">
            <div className="container">
              <div className="row">
                <div className="col-md-5 p-0 card-left">
                  <div className="card bg-primary">
                    <div style={{ backgroundColor: "#fff" }} className="svg-icon">
                      <img src={"https://timeshoppy.com/assets/images/icon/logo.png"} className="Img-fluid" />
                    </div>
                    <Slider className="single-item" {...settings}>
                      <div>
                        <div>
                          <h3>Admin Dashboard</h3>
                          <p>Manage your store, inventory on timeshoppy</p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="col-md-7 p-0 card-right">
                  <div className="card tab2-card">
                    <div className="card-body">
                      <LoginTabset history={this.props.history} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
