import * as types from "../constants/ActionTypes.js";
import { toast } from "react-toastify";
import { toggleAppState } from "./app.actions";
import "react-toastify/dist/ReactToastify.min.css";

const fetchBankDetailAction = (details) => ({
  type: types.FETCH_BANK_DETAIL,
  payload: details
});

export const fetchBankDetail = () => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchBankDetail()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchBankDetailAction(response.result));
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

export const updateBankDetail = (data) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .updateBankDetail(data)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        console.log(response)
        toast.success("Update successful");
        dispatch(toggleAppState());
      })
      .catch((res) => {
        api.errorHandler(res);
        dispatch(toggleAppState());
      });
  };
};
