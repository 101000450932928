import React, { Component, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  CustomInput,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import { SketchPicker } from "react-color";
import { Collapse } from "reactstrap";

import AddProductVariantModal from "./addProductVariantModal";
import EditProductVariantModal from "./editProductVariantModal";
import Breadcrumb from "../../common/breadcrumb";
import { RichTextField } from "../../common/form";
import { withAuthenticated } from "../../HOCs/withAuthenticated";
import {
  createStoreProduct,
  fetchProductCategories,
  fetchProductSubCategories,
} from "../../../actions";
import placeholder from "../../../assets/images/placeholder.jpg";
import uploadPlaceholder from "../../../assets/images/upload-placeholder.png";
import asFormData from "json-form-data";

const isMobileDevice = () =>
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

const initialState = {
  quantity: 1,
  file: "",
  data: null,
  tags: {
    value: "",
  },
  currentImg: [],
  dummyimgs: [
    {
      img: placeholder,
    },
  ],
  product_name: {
    value: "",
  },
  price: {
    value: 0,
  },
  discount: {
    value: 0,
  },
  category_id: {
    value: 0,
  },
  subcategory_id: {
    value: 0,
  },
  description: {
    value: "",
    error: "",
  },
  paletteList: [],
  sizes: [],
  isAddingProduct: false,
  palette: "#cecece",
  isProductVariantModalOpen: false,
  isProductVariantEditModalOpen: false,
  is_enabled: {
    value: 1,
  },
  isProductsSlideAnimating: false,
  activeProductIndex: 0,
};

export class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.editProductVariant = this.editProductVariant.bind(this);
    this.handleProductVariantSubmit = this.handleProductVariantSubmit.bind(
      this
    );
    this.handleProductVariantEditSubmit = this.handleProductVariantEditSubmit.bind(
      this
    );
    this.toggleProductVariantModal = this.toggleProductVariantModal.bind(this);
  }

  componentDidMount() {
    const { fetchProductCategories } = this.props;
    this.setState({
      data: new FormData(),
    });

    fetchProductCategories();
  }

  IncrementItem = () => {
    this.setState((prevState) => {
      if (prevState.quantity < 9) {
        return {
          quantity: prevState.quantity + 1,
        };
      } else {
        return null;
      }
    });
  };

  DecreaseItem = () => {
    this.setState((prevState) => {
      if (prevState.quantity > 1) {
        return {
          quantity: prevState.quantity - 1,
        };
      } else {
        return null;
      }
    });
  };

  handleChange = (event) => {
    this.setState({
      quantity: event.target.value,
    });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: {
        value,
      },
    });
  };

  handleDescriptionChange = (event) => {
    const { description } = this.state;
    let value = event.editor.getData();

    this.setState({
      description: {
        ...description,
        value,
      },
    });
  };

  //image upload
  _handleSubmit(e) {
    e.preventDefault();
  }

  async handleValidSubmit(e) {
    const {
      description,
      product_name,
      discount,
      price,
      quantity,
      category_id,
      subcategory_id,
      file,
      is_enabled,
      variants,
      tags,
      paletteList,
      sizes,
    } = this.state;
    const { createProduct, history, user } = this.props;

    if (!description.value.length || !category_id || !subcategory_id || !file) {
      return toast.warn("Please fill all required fields properly");
    }

    this.setState({
      isAddingProduct: true,
    });

    let product = {
      // product_image: file,
      product_name: product_name.value,
      stock: quantity,
      category_id: category_id.value,
      subcategory_id: subcategory_id.value,
      is_published: is_enabled.value,
      price: price.value,
      tags: tags.value.length ? tags.value.split(",") : [],
      discount: discount.value,
      description: description.value,
      short_description: description.value,
      variants,
      sizes,
      colors: paletteList,
    };

    createProduct(
      user.profile.store_id,
      asFormData(product, { initialFormData: this.state.data }),
      () => {
        this.setState({
          isAddingProduct: false,
        });
        this.setState({ ...initialState });
        return (window.location.href = "/products");
      }
    );

    this.setState({
      isAddingProduct: false,
    });
  }
  async handleInvalidSubmit(event, errors, values) {
    toast.warning(`At least ${errors.length} fields are not properly filled`);
  }

  _handleImgChange(e, i) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { dummyimgs } = this.state;

    this.state.data.append("product_image[]", file);
    this.setState({
      data: this.state.data,
    });

    reader.onloadend = () => {
      dummyimgs[i].img = reader.result;
      this.setState({
        file,
        dummyimgs,
        currentImg: [
          ...this.state.currentImg,
          {
            img: reader.result,
          },
        ],
      });
    };
    reader.readAsDataURL(file);
  }

  onCategoryChange(e) {
    let { value } = e.target;
    if (value > 0) {
      this.props.fetchProductSubCategories(value);
    }

    this.handleInputChange(e);
  }

  toggleProductVariantEditModal() {
    this.setState(({ isProductVariantEditModalOpen }) => ({
      isProductVariantEditModalOpen: !isProductVariantEditModalOpen,
    }));
  }

  editProductVariant(variantIdx) {
    this.setState({
      variantIdx,
    });
    this.toggleProductVariantEditModal();
  }

  deleteProductVariant(productVariantIdx) {
    if (window.confirm("Delete this variant?")) {
      let shouldKeepProduct = (_, idx) => idx !== productVariantIdx;
      this.setState(({ variants, ...state }) => {
        return {
          ...state,
          variants: variants.filter(shouldKeepProduct),
        };
      });
    }
  }

  handleProductVariantSubmit(variants) {
    this.setState({
      variants: [...this.state.variants, variants],
    });
    this.toggleProductVariantModal();
  }

  toggleProductVariantModal() {
    this.setState(({ isProductVariantModalOpen }) => ({
      isProductVariantModalOpen: !isProductVariantModalOpen,
    }));
  }

  incrementDummyImgs = () => {
    if (this.state.dummyimgs.length >= 3) return;
    this.setState(({ dummyimgs }) => ({
      dummyimgs: [
        ...dummyimgs,
        {
          img: placeholder,
        },
      ],
    }));
  };

  handleProductVariantEditSubmit({ variantIdx, variant }) {
    let newVariants = this.state.variants;
    newVariants[variantIdx] = variant;

    this.setState(({ isProductVariantEditModalOpen }) => ({
      variants: newVariants,
      isProductVariantEditModalOpen: !isProductVariantEditModalOpen,
    }));
  }

  goNext() {
    const {
      isProductsSlideAnimating,
      activeProductIndex,
      currentImg,
    } = this.state;
    if (isProductsSlideAnimating) return;
    const nextIndex =
      activeProductIndex === currentImg.length - 1 ? 0 : activeProductIndex + 1;
    this.setState({
      activeProductIndex: nextIndex,
    });
  }

  goPrevious() {
    const {
      isProductsSlideAnimating,
      activeProductIndex,
      currentImg,
    } = this.state;
    if (isProductsSlideAnimating) return;
    const nextIndex =
      activeProductIndex === 0 ? currentImg.length - 1 : activeProductIndex - 1;
    this.setState({
      activeProductIndex: nextIndex,
    });
  }

  render() {
    const {
      currentImg,
      dummyimgs,
      product_name,
      price,
      discount,
      variants,
      variantIdx,
      is_enabled,
      isAddingProduct,
      tags,
      sizes,
      palette,
      paletteList,
      isProductVariantModalOpen,
      isProductVariantEditModalOpen,
      isProductsSlideAnimating,
      activeProductIndex,
    } = this.state;
    const { categories, subCategories, user } = this.props;

    return (
      <Fragment>
        <Breadcrumb title="Add Product" parent="Product" />
        <ToastContainer />
        {isProductVariantModalOpen ? (
          <AddProductVariantModal
            isOpen={isProductVariantModalOpen}
            onClose={() => this.toggleProductVariantModal()}
            onSubmit={this.handleProductVariantSubmit}
            onProductVariantSubmit={this.handleProductVariantSubmit}
          />
        ) : null}
        {isProductVariantEditModalOpen ? (
          <EditProductVariantModal
            variant={variants[variantIdx]}
            variantIdx={variantIdx}
            isOpen={isProductVariantEditModalOpen}
            onClose={() => this.toggleProductVariantEditModal()}
            onSubmit={this.handleProductVariantEditSubmit}
          />
        ) : null}
        <div className="container-fluid">
          {user && user.profile && user.profile.store_activated_at ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Add Product</h5>
                  </div>
                  <div className="card-body">
                    <div className="row product-adding">
                      <div className="col-xl-5">
                        <div className="add-product">
                          <div className="row">
                            {currentImg.length > 0 ? (
                              <div className="col-md-5">
                                <Carousel
                                  activeIndex={activeProductIndex}
                                  next={() => this.goNext()}
                                  previous={() => this.goPrevious()}
                                >
                                  {currentImg.map(({ img }, i) => (
                                    <CarouselItem
                                      onExiting={() => true}
                                      onExited={() => false}
                                      key={i}
                                    >
                                      <img src={img} alt={"An image"} />
                                    </CarouselItem>
                                  ))}
                                  <CarouselControl
                                    direction="prev"
                                    directionText="Previous"
                                    onClickHandler={() => this.goPrevious()}
                                  />
                                  <CarouselControl
                                    direction="next"
                                    directionText="Next"
                                    onClickHandler={() => this.goNext()}
                                  />
                                </Carousel>
                              </div>
                            ) : (
                              <div className="col-md-5">
                                <img
                                  src={placeholder}
                                  alt=""
                                  className="img-fluid image_zoom_1 blur-up lazyloaded"
                                />
                              </div>
                            )}

                            <div className="col-md-7">
                              <ul className="file-upload-product row mt-2">
                                {dummyimgs.map((res, i) => {
                                  return (
                                    <li key={i} className="mb-3 col-6 col-lg-4">
                                      <div
                                        className="box-input-file"
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        <input
                                          className="upload"
                                          type="file"
                                          onChange={(e) =>
                                            this._handleImgChange(e, i)
                                          }
                                        />
                                        <div>
                                          <img
                                            className="img img-fluid"
                                            alt=""
                                            src={res.img}
                                          />
                                        </div>
                                        <a
                                          href="/"
                                          id="result1"
                                          onClick={(e) =>
                                            this._handleSubmit(e.target.id)
                                          }
                                        >
                                          {""}
                                        </a>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                              <button
                                disabled={this.state.dummyimgs.length >= 3}
                                onClick={this.incrementDummyImgs}
                                className="btn btn-dark btn-xs mt-2 ml-4 pull-left"
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-7">
                        <AvForm
                          className="needs-validation add-product-form"
                          onValidSubmit={this.handleValidSubmit}
                          onInvalidSubmit={this.handleInvalidSubmit}
                        >
                          <div className="form form-label-center">
                            <div className="form-group mb-3 row">
                              <label
                                htmlFor={"product_name"}
                                className="col-xl-3 col-sm-4 mb-0"
                              >
                                Product Name:
                              </label>
                              <div className="col-xl-8 col-sm-7">
                                <AvField
                                  className="form-control"
                                  name="product_name"
                                  id="product_name"
                                  onChange={this.handleInputChange}
                                  value={product_name.value}
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-group mb-3 row">
                              <label
                                htmlFor="price"
                                className="col-xl-3 col-sm-4 mb-0"
                              >
                                Price:
                              </label>
                              <div className="col-xl-8 col-sm-7">
                                <AvField
                                  className="form-control mb-0"
                                  name="price"
                                  id="price"
                                  type="number"
                                  min={0}
                                  onChange={this.handleInputChange}
                                  value={price.value}
                                  required
                                />
                              </div>
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div className="col-xl-8 col-sm-7 offset-4">
                                <button
                                  type="button"
                                  className="btn btn-dark"
                                  onClick={() => {
                                    const { paletteList, palette } = this.state;

                                    this.setState({
                                      paletteList: [...paletteList, palette],
                                    });
                                  }}
                                >
                                  Add {paletteList.length > 0 ? "new " : ""}{" "}
                                  color
                                </button>
                                {paletteList.length > 0 && (
                                  <p className="text-info">
                                    Select a new color and tap the button above
                                  </p>
                                )}
                              </div>
                            </div>

                            <Collapse isOpen={paletteList.length > 0}>
                              <div className="form-group my-3 row">
                                <div className="col-xl-3 col-sm-4 row mb-0">
                                  {paletteList.map((paletteItem, key) => {
                                    return (
                                      <div
                                        className="col-xs-6 p-relative"
                                        key={key}
                                      >
                                        <i
                                          onClick={() => {
                                            this.setState({
                                              paletteList: paletteList.filter(
                                                (item) => item !== paletteItem
                                              ),
                                            });
                                          }}
                                          className="fa fa-close text-danger"
                                        ></i>
                                        <div
                                          className="mb-2 mr-1"
                                          style={{
                                            backgroundColor: paletteItem,
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "100%",
                                            boxShadow: "0 0 2px #aaa",
                                          }}
                                          key={key}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="col-xl-8 col-sm-7">
                                  <SketchPicker
                                    color={palette}
                                    onChangeComplete={(color) => {
                                      let paletteList = this.state.paletteList;
                                      paletteList[paletteList.length - 1] =
                                        color.hex;
                                      this.setState({
                                        palette: color.hex,
                                        paletteList,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                              </div>
                            </Collapse>
                            <div className="form-group mb-3 row">
                              <label
                                htmlFor="tags"
                                className="col-xl-3 col-sm-4 mb-0"
                              >
                                Tags:
                              </label>
                              <div className="col-xl-8 col-sm-7">
                                {isMobileDevice() ? (
                                  <AvField
                                    name="tags"
                                    type="text"
                                    placeholder="Eg: new, cool, red, t-shirt"
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Please enter a tag",
                                      },
                                      pattern: {
                                        value: /^\w(\s*,?\s*\w)*$/g,
                                        errorMessage:
                                          "Must have at least one tag (with no spaces) separated by commas",
                                      },
                                    }}
                                    onChange={this.handleInputChange}
                                    value={tags.value}
                                  />
                                ) : (
                                  <>
                                    <TagsInput
                                      value={
                                        tags.value.length
                                          ? tags.value.split(",")
                                          : []
                                      }
                                      addKeys={[188, 9, 13, 32, 44]}
                                      inputProps={{
                                        placeholder: "Type a tag",
                                      }}
                                      onChange={(tags) =>
                                        this.setState({
                                          tags: {
                                            value: tags.join(","),
                                          },
                                        })
                                      }
                                    />
                                    <p className="small text-muted">
                                      Example: new, cool, red, mobile
                                    </p>
                                  </>
                                )}
                              </div>
                              <div className="valid-feedback">Looks good!</div>
                            </div>

                            <div className="form-group mb-3 row">
                              <label
                                htmlFor="sizes"
                                className="col-xl-3 col-sm-4 mb-0"
                              >
                                Sizes:
                              </label>
                              <div className="col-xl-8 col-sm-7">
                                <>
                                  <TagsInput
                                    value={sizes}
                                    addKeys={[188, 9, 13, 32, 44]}
                                    inputProps={{
                                      placeholder: "Type a size",
                                    }}
                                    tagProps={{
                                      className:
                                        "bg-dark text-white react-tagsinput-tag",
                                      classNameRemove:
                                        "text-white react-tagsinput-remove",
                                    }}
                                    onChange={(sizes) =>
                                      this.setState({ sizes: [...sizes] })
                                    }
                                  />
                                  <p className="small text-muted">
                                    Example: XS, M, L, XL, XXL
                                  </p>
                                </>
                              </div>
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-group mb-3 row">
                              <label
                                htmlFor="discount"
                                className="col-xl-3 col-sm-4 mb-0"
                              >
                                Discount ({discount.value}%):
                              </label>
                              <div className="col-xl-8 col-sm-7">
                                {/* <AvField className="form-control mb-0" name="discount" id="discount" type="range" min={0} step={1} max={100} onChange={this.handleInputChange} value={discount.value} required /> */}
                                <CustomInput
                                  type="range"
                                  name="discount"
                                  id="discount"
                                  min={0}
                                  step={1}
                                  max={100}
                                  onChange={this.handleInputChange}
                                  value={discount.value}
                                  required
                                />
                              </div>
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                          </div>
                          <div className="form">
                            <div className="form-group row">
                              <label
                                className="col-xl-3 col-sm-4 mb-0"
                                htmlFor="category_id"
                              >
                                Select category
                              </label>
                              <div className="col-xl-8 col-sm-7">
                                <select
                                  className="form-control digits"
                                  name="category_id"
                                  id="category_id"
                                  onChange={this.onCategoryChange}
                                >
                                  <option value={0}>Choose a category</option>
                                  {categories.map(({ category_label, id }) => (
                                    <option key={id} value={id}>
                                      {category_label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {subCategories.length ? (
                              <div className="form-group row">
                                <label
                                  className="col-xl-3 col-sm-4 mb-0"
                                  onChange={this.handleInputChange}
                                  name="subcategory_id"
                                  htmlFor="subcategory_id"
                                >
                                  Select subcategory
                                </label>
                                <div className="col-xl-8 col-sm-7">
                                  <select
                                    className="form-control digits"
                                    name="subcategory_id"
                                    id="subcategory_id"
                                    onChange={this.handleInputChange}
                                  >
                                    <option value={0}>
                                      Choose a subcategory
                                    </option>
                                    {subCategories.map(
                                      ({ sub_category_label, id }) => (
                                        <option key={id} value={id}>
                                          {sub_category_label}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            ) : null}
                            <div className="form-group row">
                              <label className="col-xl-3 col-sm-4 mb-0">
                                Total Stock :
                              </label>
                              <fieldset className="qty-box ml-0">
                                <div className="input-group bootstrap-touchspin">
                                  <div className="input-group-prepend">
                                    <button
                                      className="btn btn-primary btn-square bootstrap-touchspin-down"
                                      type="button"
                                      onClick={this.DecreaseItem}
                                    >
                                      <i className="fa fa-minus"></i>
                                    </button>
                                  </div>
                                  <div className="input-group-prepend">
                                    <span className="input-group-text bootstrap-touchspin-prefix"></span>
                                  </div>
                                  <input
                                    className="touchspin form-control"
                                    type="number"
                                    value={this.state.quantity}
                                    onChange={this.handleChange}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text bootstrap-touchspin-postfix"></span>
                                  </div>

                                  <div className="input-group-append ml-0">
                                    <button
                                      className="btn btn-primary btn-square bootstrap-touchspin-up"
                                      type="button"
                                      onClick={this.IncrementItem}
                                    >
                                      <i className="fa fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            <RichTextField
                              label={"Description: "}
                              onBlur={this.onBlur}
                              content={this.state.description.value}
                              onChange={(e) => this.handleDescriptionChange(e)}
                              afterPaste={this.afterPaste}
                            />
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Publish this product?
                            </label>

                            <div className="m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0">
                              <label className="d-block mb-0 mr-3">
                                <input
                                  className="radio_animated"
                                  type="radio"
                                  value={1}
                                  name="is_enabled"
                                  onChange={this.handleInputChange}
                                  defaultChecked={is_enabled.value}
                                />
                                Yes
                              </label>
                            </div>

                            <div className="m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0">
                              <label className="d-block mb-0 mr-3">
                                <input
                                  className="radio_animated"
                                  type="radio"
                                  value={0}
                                  name="is_enabled"
                                  onChange={this.handleInputChange}
                                  defaultChecked={!is_enabled.value}
                                />
                                No
                              </label>
                            </div>
                          </div>
                          <div className="offset-xl-3 offset-sm-4">
                            <button
                              type="submit"
                              disabled={isAddingProduct}
                              className="btn btn-primary"
                            >
                              {isAddingProduct ? "Adding product..." : "Add"}
                            </button>
                            <button type="button" className="btn btn-light">
                              Discard
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : user.profile &&
            user.profile.store &&
            !user.profile.store_activated_at ? (
            <div className="card text-center py-4">
              <img
                style={{ maxWidth: "40%", margin: "auto" }}
                src="https://i.ibb.co/vQGj8K0/undraw-create-f05x.png"
                alt="undraw-clean-up-ucm0"
              />
              <div className="w-50 mx-auto">
                <h4 className="">Awaiting activation</h4>
                <p className="mb-3">
                  Your store is awaiting activation and you will be able to add
                  products once active.
                </p>
              </div>
            </div>
          ) : (
            <div className="card text-center py-4">
              <img
                style={{ maxWidth: "70%", margin: "auto" }}
                src="https://i.ibb.co/vQGj8K0/undraw-create-f05x.png"
                alt="undraw-clean-up-ucm0"
              />
              <div className="w-50 mx-auto">
                <h4 className="">Create a store or wait for activation.</h4>
                <p className="mb-3">
                  You have to create a store or wait for store activation to be
                  able to add a product
                </p>
                <div style={{ width: "100%" }}>
                  <a
                    href={`${process.env.PUBLIC_URL}/stores/create`}
                    className="btn btn-primary"
                  >
                    Create store now
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.store.categories,
    user: state.user,
    subCategories: state.store.subCategories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createProduct: bindActionCreators(createStoreProduct, dispatch),
  fetchProductCategories: bindActionCreators(fetchProductCategories, dispatch),
  fetchProductSubCategories: bindActionCreators(
    fetchProductSubCategories,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthenticated(AddProduct));
