import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { withAuthenticated } from "../HOCs/withAuthenticated";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  dropMessages,
  renderCustomComponent,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";

import {
  fetchOrderNotifications,
  addOrderShippingFee as addOrderShippingFeeAction,
  updateOrder as updateOrderAction,
  getSingleOrder as getSingleOrderAction,
  confirmSellerDelivery,
  sendChatMessage,
  fetchChatMessages,
} from "../../actions";
import OrderModal from "./orderModal";

const isNotDeclined = ({ declined_at }) => !declined_at;
const isCompleted = ({ delivered_at }) => delivered_at;

const transformNotifications = (notifications) => {
  return notifications.filter(
    (item) => isNotDeclined(item) && !isCompleted(item)
  );
};
const completedNotifications = (notifications) => {
  return notifications.filter(
    (item) => isNotDeclined(item) && isCompleted(item)
  );
};
const declinedNotifications = (notifications) => {
  return notifications.filter((item) => !isNotDeclined(item));
};

const messagesReducer = (prev, current) => ({
  ...prev,
  [current.order_id]: current.conversationMessage,
});

export class Orders extends Component {
  state = {
    activeTab: "active-orders",
    isAddingOrderShippingFee: false,
    isViewingOrder: null,
    activeConversation: 0,
    messages: {},
    hasFetchedOrders: false,
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
  }

  async componentDidUpdate() {
    const { hasFetchedOrders } = this.state;
    const { user } = this.props;

    if (!hasFetchedOrders && user.profile) {
      const { fetchNotifications } = this.props;

      await fetchNotifications(user.profile.role_label);
      this.setState({ hasFetchedOrders: true });
    }
  }

  setOrder = (order) => {
    this.setState(() => ({
      isViewingOrder: order,
    }));
  };

  toggleFormProcessing() {
    this.setState(({ isAddingOrderShippingFee }) => ({
      isAddingOrderShippingFee: !isAddingOrderShippingFee,
    }));
  }

  populateResponses = ({ messages, userId }) => {
    let encounteredAuthorMessage = false;
    const role = "Buyer";

    return (
      messages &&
      messages.map(({ user_id, message, user }, key) => {
        const senderIsAuthor = parseInt(user_id) === parseInt(userId);

        if (senderIsAuthor) {
          if (!encounteredAuthorMessage)
            renderCustomComponent(() => (
              <div className={`small text-muted w-100 text-right`}>
                You said:
              </div>
            ));

          encounteredAuthorMessage = true;
        } else {
          renderCustomComponent(() => (
            <div className={`small text-muted w-100 text-left`}>
              {`${user.user_role.role_label} said:`}
            </div>
          ));
        }

        return senderIsAuthor
          ? addUserMessage(message)
          : addResponseMessage(message);
      })
    );
  };

  handleNewUserMessage = (message) => {
    this.props.sendChatMessage({
      message,
      order_id: this.state.activeConversation,
    });
  };

  handleActivateMessageBox = (conversationId) => {
    const { activeConversation, messages } = this.state;
    const { fetchChatMessages, user } = this.props;

    if (activeConversation !== conversationId) {
      fetchChatMessages(conversationId, (msgs) => {
        const chatMessages = msgs.reduce(messagesReducer, messages);
        this.setState({
          messages: chatMessages,
        });

        dropMessages();
        this.setState({
          activeConversation: conversationId,
        });

        this.populateResponses({
          messages: chatMessages[conversationId],
          userId: user.profile.id,
        });
      });
    }

    toggleWidget();
  };

  renderOrdersList({ orders, type = "active", symbol = "₦" }) {
    return (
      <div className="user-status table-responsive latest-order-table">
        <table className="table table-bordernone">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Total</th>
              <th scope="col">Placed on</th>
              <th scope="col">Paid on</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(
              (
                { id, order_id, amount, shipping_cost, created_at, is_paid_at },
                key
              ) => (
                <tr key={key}>
                  <td>{id ? id : order_id}</td>
                  <td className="digits">
                    {symbol} {amount}
                  </td>
                  <td className="font-danger">
                    <b>{new Date(created_at).toLocaleString()}</b>
                  </td>
                  <td className={is_paid_at ? `text-info` : `text-danger`}>
                    <b>
                      {is_paid_at
                        ? new Date(is_paid_at).toLocaleString()
                        : "Not paid"}
                    </b>
                  </td>
                  <td className="digits">
                    {is_paid_at !== null ? (
                      <button
                        className="btn btn-light btn-sm mr-2"
                        onClick={() =>
                          this.handleActivateMessageBox(id ? id : order_id)
                        }
                      >
                        <i className="fa fa-comments"></i> Chat
                      </button>
                    ) : null}
                  </td>
                  <td className="digits">
                    <button
                      className="btn btn-default btn-sm"
                      onClick={() => {
                        this.props.getOrder(id ? id : order_id, () => {
                          this.setOrder({
                            id: id ? id : order_id,
                            shippingCost: shipping_cost,
                          });
                        });
                      }}
                    >
                      <i className="fa fa-cog"></i> &nbsp; Manage{" "}
                      <div
                        className="dotted-animation"
                        style={{ right: "-20px" }}
                      >
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                      </div>
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
  render() {
    const { notifications, activeOrder } = this.props;
    const { activeTab, isViewingOrder, isAddingOrderShippingFee } = this.state;

    return (
      <Fragment>
        {activeOrder ? (
          <OrderModal
            isOpen={!!isViewingOrder}
            shippingCost={isViewingOrder ? isViewingOrder.shipping_cost : null}
            isSubmitting={isAddingOrderShippingFee}
            onClose={() => this.setOrder(null)}
            order={activeOrder}
            onDeclineOrder={({ id }) => {
              if (
                !window.confirm(
                  "Are you sure you'd like to decline this order?"
                )
              )
                return;

              this.props.updateOrder(
                {
                  orderId: id,
                  payload: {
                    is_accepted: 0,
                  },
                  type: "declined",
                },
                () => {
                  this.props.fetchNotifications();
                  this.setOrder(null);
                }
              );
            }}
            onDeliverOrder={({ id }) => {
              this.props.confirmSellerDelivery(id, () => {
                this.props.fetchNotifications();
                this.setOrder(null);
              });
            }}
            onSubmit={({ shipping_fee }) => {
              const { addOrderShippingFee, fetchNotifications } = this.props;
              this.toggleFormProcessing();
              addOrderShippingFee(
                {
                  orderId: isViewingOrder.id,
                  payload: {
                    shipping_cost: parseInt(shipping_fee, 10),
                  },
                },
                () => {
                  this.toggleFormProcessing();
                  fetchNotifications();
                  this.setOrder(null);
                }
              );
            }}
          />
        ) : null}
        <Breadcrumb title="Orders" parent="Sales" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Latest Orders</h5>
                </div>
                <div className="card-body">
                  <nav>
                    <div
                      className="nav nav-tabs mb-3"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className={`nav-item nav-link ${
                          activeTab === "active-orders" && "active"
                        }`}
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-activeOrders"
                        aria-selected="true"
                        onClick={() =>
                          this.setState({
                            activeTab: "active-orders",
                          })
                        }
                      >
                        Active Orders
                      </button>
                      <button
                        className={`nav-item nav-link ${
                          activeTab === "completed-orders" && "active"
                        }`}
                        id="nav-completed-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-completed"
                        aria-selected="false"
                        onClick={() => {
                          this.setState({
                            activeTab: "completed-orders",
                          });
                        }}
                      >
                        Completed Orders
                      </button>
                      <button
                        className={`nav-item nav-link ${
                          activeTab === "declined-orders" && "active"
                        }`}
                        id="nav-declined-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-declined"
                        aria-selected="false"
                        onClick={() => {
                          this.setState({
                            activeTab: "declined-orders",
                          });
                        }}
                      >
                        Declined Orders
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content">
                    <div
                      className={`tab-pane ${
                        activeTab === "active-orders" && "show active"
                      } fade`}
                      role="tabpanel"
                      aria-labelledby="nav-activeOrders-tab"
                    >
                      {transformNotifications(notifications).length ? (
                        this.renderOrdersList({
                          orders: transformNotifications(notifications),
                          type: "active",
                        })
                      ) : (
                        <p className="card-text">
                          No active orders available at the moment
                        </p>
                      )}
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "completed-orders" && "show active"
                      } fade`}
                      role="tabpanel"
                      aria-labelledby="nav-completedOrders-tab"
                    >
                      {completedNotifications(notifications).length ? (
                        this.renderOrdersList({
                          orders: completedNotifications(notifications),
                          type: "completed",
                        })
                      ) : (
                        <p className="card-text">
                          No completed orders available at the moment
                        </p>
                      )}
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "declined-orders" && "show active"
                      } fade`}
                      role="tabpanel"
                      aria-labelledby="nav-completedOrders-tab"
                    >
                      {declinedNotifications(notifications).length ? (
                        this.renderOrdersList({
                          orders: declinedNotifications(notifications),
                          type: "declined",
                        })
                      ) : (
                        <p className="card-text">
                          No declined orders available at the moment
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Widget
          title={"Conversation with Buyer"}
          subtitle="The admin is monitoring messages"
          handleNewUserMessage={this.handleNewUserMessage}
          senderPlaceHolder="Message here..."
          showCloseButton={true}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.user.orderNotifications,
    user: state.user,
    activeOrder: state.store.activeOrder,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: bindActionCreators(fetchOrderNotifications, dispatch),
  addOrderShippingFee: bindActionCreators(addOrderShippingFeeAction, dispatch),
  confirmSellerDelivery: bindActionCreators(confirmSellerDelivery, dispatch),
  updateOrder: bindActionCreators(updateOrderAction, dispatch),
  getOrder: bindActionCreators(getSingleOrderAction, dispatch),
  fetchChatMessages: bindActionCreators(fetchChatMessages, dispatch),
  sendChatMessage: bindActionCreators(sendChatMessage, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthenticated(Orders));
