import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuthenticated } from '../HOCs/withAuthenticated';
import {
  fetchCountries,
  fetchCountryStates,
  fetchStateProvinces,
  createStore,
} from '../../actions';
import Breadcrumb from '../common/breadcrumb';
import { InputField, RadioField, SelectField } from '../common/form';
import { storeValidatorSchema } from '../../utils/validators';
import useForm from 'react-valida-hook';
import { toast } from 'react-toastify';
let slugify = require('slugify');

export function CreateStore({
  countries,
  states,
  provinces,
  history,
  user,
  fetchCountries,
  fetchCountryStates,
  fetchStateProvinces,
  createStore,
}) {
  const initialState = {
    store_name: '',
    store_address: '',
    store_formatted_address: '',
    sell_outside_province: 0,
    sell_outside_state: 0,
    country_id: 1,
    state_id: 0,
    province_id: 0,
  };
  const countriesList = [
    {
      label: 'Choose a country',
      value: 0,
    },
  ].concat(
    countries.map((country) => ({
      label: country.name,
      value: country.id,
    }))
  );

  const statesList = [
    {
      label: 'Choose a state',
      value: 0,
    },
  ].concat(
    states.map((state) => ({
      label: state.state_label,
      value: state.id,
    }))
  );

  const provincesList = [
    {
      label: 'Choose a locality',
      value: 0,
    },
  ].concat(
    provinces.map((province) => ({
      label: province.province_label,
      value: province.id,
    }))
  );

  let [formData, validation, validateForm, getData] = useForm(
    initialState,
    storeValidatorSchema
  );
  let [isSubmitting, setSubmitting] = React.useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateForm()) return;
    setSubmitting(true);
    let data = getData();
    data['store_formatted_address'] = slugify(data['store_address']);

    await createStore(data, function (response) {
      toast.success('Successfully created store');
      setTimeout(function () {
        setSubmitting(false);
        history.push('/products/add-product');
      }, 2000);
    });
    setSubmitting(false);
  }

  React.useEffect(() => {
    fetchCountries();
    fetchCountryStates(1);
  }, [fetchCountries, fetchCountryStates]);

  return (
    <Fragment>
      <Breadcrumb title="Create Store" parent="Stores" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            {!user.profile && (
              <div className="card">
                <div className="card-body py-5 text-center">
                  <h5>Loading...</h5>
                </div>
              </div>
            )}
            {user.profile && !user.profile.store_id ? (
              <div className="card">
                <div className="card-header">
                  <h5>Create Store</h5>
                </div>
                <div className="card-body">
                  <form
                    className="needs-validation user-add"
                    noValidate=""
                    onSubmit={handleSubmit}
                  >
                    <InputField
                      validation={validation}
                      formData={formData}
                      label={'Store Name'}
                      field={'store_name'}
                      placeholder="Store Name"
                    />
                    <InputField
                      validation={validation}
                      formData={formData}
                      label={'Store Address'}
                      field={'store_address'}
                      placeholder="Store Address"
                    />
                    <SelectField
                      validation={validation}
                      formData={formData}
                      field={'country_id'}
                      label={'Country'}
                      options={countriesList}
                      onChange={(e) => {
                        fetchCountryStates(e.target.value);
                      }}
                    />
                    <SelectField
                      validation={validation}
                      formData={formData}
                      field={'state_id'}
                      label={'State'}
                      options={statesList}
                      onChange={(e) => {
                        fetchStateProvinces(e.target.value);
                      }}
                    />
                    <SelectField
                      validation={validation}
                      formData={formData}
                      field={'province_id'}
                      label={'Locality'}
                      options={provincesList}
                    />
                    <RadioField
                      label={'Sell within state alone?'}
                      validation={validation}
                      formData={formData}
                      field={'sell_outside_state'}
                      options={[
                        { label: 'Yes', value: 1 },
                        { label: 'No', value: 0 },
                      ]}
                    />
                    <RadioField
                      label={'Sell within locality alone?'}
                      validation={validation}
                      formData={formData}
                      field={'sell_outside_province'}
                      options={[
                        { label: 'Yes', value: 1 },
                        { label: 'No', value: 0 },
                      ]}
                    />
                    <div className="">
                      <button
                        type="submit"
                        className="btn btn-primary pull-right"
                        disabled={isSubmitting}
                      >
                        Create store
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : user.profile ? (
              <div className="card">
                <div className="card-body py-5 text-center">
                  <h5>You have already created a store</h5>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    countries: state.metadata.countries,
    states: state.metadata.states,
    provinces: state.metadata.provinces,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: bindActionCreators(fetchCountries, dispatch),
  fetchCountryStates: bindActionCreators(fetchCountryStates, dispatch),
  fetchStateProvinces: bindActionCreators(fetchStateProvinces, dispatch),
  createStore: bindActionCreators(createStore, dispatch),
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(CreateStore)
);
