import fetch from "isomorphic-fetch";
const { API_URL } = process.env;

/**
 * Creates a user http request.
 */
function fetchCountries() {
  return fetch(`${API_URL}/api/v1/Metadata`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

/**
 * Creates a user http request.
 */
function fetchProvincesByState(stateId) {
  return fetch(`${API_URL}/api/v1/Metadata/Provinces/${stateId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

/**
 * Creates a user http request.
 */
function fetchStatesByCountry(countryId) {
  return fetch(`${API_URL}/api/v1/MetaData/States/${countryId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

export default {
  fetchCountries,
  fetchStatesByCountry,
  fetchProvincesByState
};
