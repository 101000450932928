import React, { Component } from "react";
import man from "../../../assets/images/dashboard/man.png";

export class User_panel extends Component {
  render() {
    const { role, balance, firstName } = this.props;

    return (
      <div>
        <div className="sidebar-user text-center">
          <div>
            <img
              className="img-60 rounded-circle lazyloaded blur-up bg-light"
              src={man}
              alt="#"
            />
          </div>
          <h6 className="mt-3 f-14">Welcome, {firstName}</h6>
          <p>{role}</p>
          {balance !== null && (
            <p>
              Wallet: <b className={"text-success"}>${balance}</b>
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default User_panel;
