import {
  FETCH_STORES,
  FETCH_ORDER,
  FETCH_STORE_PRODUCTS,
  FETCH_PRODUCT_CATEGORIES,
  FETCH_PRODUCT_SUB_CATEGORIES,
  FETCH_ANALYTICS
} from "../constants/ActionTypes.js";

const initialState = {
  stores: [],
  products: [],
  categories: [],
  subCategories: [],
  activeOrder: null,
  analytics: []
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STORES:
      const { stores } = action;
      return { ...state, stores };

    case FETCH_STORE_PRODUCTS:
      const { products } = action;
      return { ...state, products };

    case FETCH_PRODUCT_CATEGORIES:
      const { categories } = action;
      return { ...state, categories };

    case FETCH_PRODUCT_SUB_CATEGORIES:
      const { subCategories } = action;
      return { ...state, subCategories };

    case FETCH_ORDER:
      const { order } = action;
      return { ...state, activeOrder: order };

    case FETCH_ANALYTICS:
      const { analytics } = action;
      return { ...state, analytics };

    default:
      return state;
  }
}
