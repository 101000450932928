import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import { withRouter } from "react-router-dom";
import useForm from "react-valida-hook";

import { loginUser } from "../../actions";
import { loginValidatorSchema, formatErrs } from "../../utils/validators";

const initialState = {
  email: "",
  password: "",
};

export function LoginTabset({ history, handleUserLogin }) {
  let [formData, validation, validateForm, getData] = useForm(
    initialState,
    loginValidatorSchema
  );
  let [isSubmitting, setSubmitting] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setSubmitting(true);

    handleUserLogin(getData(), function (response) {
      setTimeout(function () {
        setSubmitting(false);
        history.push("/dashboard");
      }, 2000);
    });

    setSubmitting(false);
  };

  return (
    <div>
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link">
              <User />
              Login
            </Tab>
          </TabList>

          <TabPanel>
            <form
              className="form-horizontal auth-form"
              method="POST"
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <label htmlFor="email" className={"form-label"}>
                  Email
                </label>
                <input
                  required=""
                  name="email"
                  type="email"
                  id="email"
                  className="form-control"
                  {...formData.email.input}
                />
                {validation.errors.email && (
                  <p className="text text-danger">
                    {formatErrs(validation.errors.email)}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  required=""
                  name="password"
                  type="password"
                  id="password"
                  className="form-control"
                  {...formData.password.input}
                />
                {validation.errors.password && (
                  <p className="text text-danger">
                    {formatErrs(validation.errors.password)}
                  </p>
                )}
              </div>
              <div className="form-button">
                <button
                  className="btn btn-primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Login
                </button>
              </div>
            </form>
          </TabPanel>
        </Tabs>
      </Fragment>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    countries: state.metadata.countries,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleUserLogin: bindActionCreators(loginUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginTabset));
