import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  ShoppingBag,
  Chrome,
  BarChart,
  Settings,
  Archive,
  LogIn,
} from 'react-feather';

export const MENUITEMS = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },
  {
    title: 'Create product',
    path: '/products/add-product',
    icon: Box,
    type: 'link',
    active: false,
    isVisibleTo: ['customer'],
  },
  {
    title: 'View Products',
    path: '/products',
    icon: Archive,
    type: 'link',
    active: false,
    isVisibleTo: ['customer'],
  },
  {
    title: 'View Orders',
    path: '/sales/orders',
    icon: ShoppingBag,
    type: 'link',
    active: false,
    isVisibleTo: ['customer'],
  },
  {
    title: 'Every Order',
    path: '/sales/all-orders',
    icon: DollarSign,
    type: 'link',
    active: false,
    isVisibleTo: ['super admin'],
  },
  {
    title: 'Wallet',
    icon: DollarSign,
    type: 'link',
    path: '/wallet',
    active: false,
    isVisibleTo: ['customer'],
  },
  {
    title: 'Create Store',
    icon: Users,
    type: 'link',
    path: '/stores/create',
    active: false,
    isVisibleTo: ['customer'],
  },
  {
    title: 'View Stores',
    icon: Users,
    type: 'link',
    path: '/stores',
    active: false,
    isVisibleTo: ['super admin'],
  },
  {
    title: 'List users',
    icon: Users,
    type: 'link',
    path: '/users/list-users',
    active: false,
    isVisibleTo: ['super admin'],
  },
  {
    title: 'Logout',
    icon: LogIn,
    type: 'link',
    path: '/logout',
    active: false,
  },
  // {
  //     title: 'Localization', icon: Chrome, type: 'sub', children: [
  //         { path: '/localization/transactions', title: 'Translations', type: 'link' },
  //         { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
  //         { path: '/localization/taxes', title: 'Taxes', type: 'link' }
  //     ]
  // },
  // {
  //     title: 'Reports',path:'/reports/report', icon: BarChart, type: 'link', active: false
  // },
  // {
  //     title: 'Settings', icon: Settings, type: 'sub', children: [
  //         { path: '/settings/profile', title: 'Profile', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Invoice',path:'/invoice', icon: Archive, type: 'link', active: false
  // },
];
