import fetch from 'isomorphic-fetch';
import { getToken } from '../../utils';
const { API_URL } = process.env;

/**
 * Retrieves a list of available banks
 */
async function fetchBanks() {
  const res = fetch(`${API_URL}/api/v1/banks`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

  return res;
}

export default {
  fetchBanks,
};
