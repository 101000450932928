// Stores
export const CREATE_STORE = "CREATE_STORE";
export const FETCH_STORES = "FETCH_STORES";
export const FETCH_STORE_PRODUCTS = "FETCH_STORE_PRODUCTS";
export const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
export const FETCH_PRODUCT_SUB_CATEGORIES = "FETCH_PRODUCT_SUB_CATEGORIES";
export const TOGGLE_APP_STATE = "TOGGLE_APP_STATE";

// Register & Login User
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const SET_PROFILE = "SET_PROFILE";

// Banks and Bank Detail
export const FETCH_BANKS = "FETCH_BANKS";
export const FETCH_BANK_DETAIL = "FETCH_BANK_DETAIL";
export const UPDATE_BANK_DETAIL = "UPDATE_BANK_DETAIL";

// Wallet and Wallet Transactions (Cashflows)
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const FETCH_WALLET_BALANCE = "FETCH_WALLET_BALANCE";
export const FETCH_WALLET_TRANSACTIONS = "FETCH_WALLET_TRANSACTIONS";

// Country, States & Provinces
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_COUNTRY_STATES = "FETCH_COUNTRY_STATES";
export const FETCH_STATE_PROVINCES = "FETCH_STATE_PROVINCES";

// Product variant features
export const UPDATE_PRODUCT_VARIANT = "UPDATE_PRODUCT_VARIANT";
export const ADD_FEATURE = "ADD_FEATURE";
export const EDIT_FEATURE = "EDIT_FEATURE";
export const DELETE_FEATURE = "DELETE_FEATURE";

// Orders
export const FETCH_ORDER_NOTIFICATIONS = "FETCH_ORDER_NOTIFICATIONS";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ANALYTICS = "FETCH_ANALYTICS";

// Users
export const SET_USERS = "SET_USERS";
