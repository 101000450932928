import { combineReducers } from "redux";

// Import custom components
import metadataReducer from "./metadata";
import userReducer from "./user";
import storeReducer from "./store";
import appReducer from "./app";
import walletReducer from "./wallet";
import bankReducer from "./bank";
import bankDetailReducer from "./bankDetail";

const rootReducer = combineReducers({
  metadata: metadataReducer,
  user: userReducer,
  store: storeReducer,
  app: appReducer,
  wallet: walletReducer,
  banks: bankReducer,
  bankDetail: bankDetailReducer,
});

export default rootReducer;
