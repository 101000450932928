import fetch from 'isomorphic-fetch';
import { getToken } from '../../utils';
const { API_URL } = process.env;

/**
 * Retrieves a list of transactions on the wallet of a user.
 */
function fetchWalletTransactions() {
  return fetch(`${API_URL}/api/v1/wallets/transactions`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Creates a user http request.
 */
function fundWallet(payload) {
  console.log(payload);
  return fetch(`${API_URL}/api/v1/Paystack/Pay`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Withdraws from a wallet.
 * @param {object} payload - an object containing data for the withdraw
 * @return {Promise<object>}
 */
function withdrawFromWallet(payload) {
  console.log(payload)
  return fetch(`${API_URL}/api/v1/wallets/withdraw`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: payload
  });
}

export default {
  fetchWalletTransactions,
  fundWallet,
  withdrawFromWallet
};
