import {
  FETCH_COUNTRIES,
  FETCH_COUNTRY_STATES,
  FETCH_STATE_PROVINCES
} from "../constants/ActionTypes.js";

const initialState = {
  countries: [],
  states: [],
  provinces: [],
  general_settings: null
};

export default function metadataReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUNTRIES:
      const { countries } = action;

      return { ...state, countries };

    case FETCH_COUNTRY_STATES:
      const { states } = action;

      return { ...state, states };

    case FETCH_STATE_PROVINCES:
      const { provinces } = action;

      return { ...state, provinces };

    case "FETCH_SETTINGS":
      const { general_settings } = action;

      return { ...state, general_settings };

    default:
      return state;
  }
}
