const requiredEmail = [
  {
    name: "email",
    type: "required",
    stateMap: "email"
  },
  {
    name: "email",
    type: "required",
    stateMap: "email"
  }
];

let createValidatorField = ({ name, stateMap, type = "required" }) => {
  return {
    name,
    stateMap: stateMap ? stateMap : name,
    type
  };
};

export const loginValidatorSchema = [
  ...requiredEmail,
  createValidatorField({
    name: "password"
  })
];

export const storeValidatorSchema = [
  createValidatorField({
    name: "store_name"
  }),
  createValidatorField({
    name: "sell_outside_state"
  }),
  createValidatorField({
    name: "sell_outside_province"
  }),
  createValidatorField({
    name: "country_id"
  }),
  createValidatorField({
    name: "state_id"
  }),
  createValidatorField({
    name: "province_id"
  }),
  createValidatorField({
    name: "store_address"
  })
];

export const productValidatorSchema = [
  createValidatorField(
    {
      name: "product_name"
    },
    {
      name: "category_id"
    },
    {
      name: "sub_category_id"
    },
    {
      name: "is_published"
    }
  )
];

export const variantValidatorSchema = [
  createValidatorField(
    {
      name: "variant_name"
    },
    {
      name: "sku"
    },
    {
      name: "price_addon"
    }
  )
];

export const orderNotificationsValidatorSchema = [
  createValidatorField({
    name: "shipping_fee"
  })
];

export const walletFundingValidatorSchema = [
  createValidatorField({
    name: "amount",
  }),
  createValidatorField({
    name: "transaction_type_id",
  })
];

export const withdrawalValidatorSchema = [
  createValidatorField({
      name: "amount",
  }),
  createValidatorField({
    name: "password"
  })
];

export const bankDetailValidatorSchema = [
  createValidatorField({
      name: "bank_id",
  }),
  createValidatorField({
      name: "account_name",
  }),
  createValidatorField({
    name: "account_number"
  })
];

const validationMessages = {
  isEmail: "Please provide a valid email",
  required: "Please fill this field"
};

export const formatErrs = errs => {
  return errs && errs.map(err => validationMessages[err.trim()]).join(". ");
};
