import { TOGGLE_APP_STATE } from "../constants/ActionTypes.js";

const initialState = {
  isLoading: false,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_APP_STATE:
      return { ...state, isLoading: !state.isLoading };

    default:
      return state;
  }
}
