import * as types from '../constants/ActionTypes.js';
import { toast } from 'react-toastify';
import { toggleAppState } from '../actions/app.actions';
import 'react-toastify/dist/ReactToastify.min.css';

export const confirmUser = (token, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .confirmUser(token)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(toggleAppState());
        callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const updateUserProfile = (user, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .updateUserProfile(user)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(toggleAppState());
        toast.success('Successfully updated profile');
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

const setUserProfileAction = (profile) => ({
  type: types.SET_PROFILE,
  profile,
});

export const getUserProfile = (callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    if (getState().user.profile) return;
    return api
      .getUserProfile()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(api.setToken)
      .then((response) => {
        console.log(response.result[0]);
        const user = response.result[0];
        if (
          user.role_label !== 'Super Admin' &&
          user.role_label !== 'Customer'
        ) {
          toast.error('You must be a seller or admin to access this area');
          return callback();
        }
        dispatch(setUserProfileAction(response.result[0]));
        dispatch(toggleAppState());
      })
      .catch((e) => {
        if ('response' in e) {
          api.errorHandler(e);
          return callback(e);
        }
      });
  };
};

const setOrderNotificationsAction = (orderNotifications) => ({
  type: types.FETCH_ORDER_NOTIFICATIONS,
  orderNotifications,
});

export const fetchOrderNotifications = (
  role_label = 'customer',
  callback = () => {}
) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchOrderNotifications({ role_label })
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(toggleAppState());
        dispatch(setOrderNotificationsAction(response.results));
      })
      .catch((e) => {
        api.errorHandler(e);
        return callback(e);
      });
  };
};

export const fetchChatMessages = (orderId, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchChatMessages(orderId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(toggleAppState());
        return callback(response.results);
      })
      .catch((e) => api.errorHandler(e));
  };
};

export const sendChatMessage = (payload, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .sendChatMessage(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(toggleAppState());
        return callback(response.results);
      })
      .catch((e) => {
        api.errorHandler(e);
        return callback(e);
      });
  };
};

export const setBanStatus = (userId, status, callback = () => {}) => {
  const codes = {
    unban: 0,
    ban: 1,
  };

  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .setBanStatus(userId, { is_ban: codes[status] })
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        toast.success(`Successfully ${status} user with id: ${userId}`);
        dispatch(toggleAppState());
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const fetchUsers = (callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchUsers()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch({
          type: types.SET_USERS,
          users: response.result,
        });
        dispatch(toggleAppState());
        return callback(response.result);
      })
      .catch((e) => api.errorHandler(e));
  };
};
