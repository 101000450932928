import * as types from "../constants/ActionTypes.js";
import { toast } from "react-toastify";
import { toggleAppState } from "../actions/app.actions";
import "react-toastify/dist/ReactToastify.min.css";

const fetchStoresAction = stores => ({
  type: types.FETCH_STORES,
  stores
});

export const fetchStores = () => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchStores()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchStoresAction(response.result));
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

export const setStoreStatus = (store, status) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .setStoreStatus(store.id, status)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        let actionLabels = {
          0: "deactivated",
          1: "activated"
        };
        toast.success(
          `Successfully ${actionLabels[status]} store: ${store.name}`
        );
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

const createStoreAction = user => ({
  type: types.CREATE_STORE,
  user
});

export const createStore = (store, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .createStore(store)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(createStoreAction(store));
        toast.success("Successfully registered");
        dispatch(toggleAppState());
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const createStoreProduct = (storeId, payload, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .createStoreProduct(storeId, payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success(`Successfully created product.`);
        dispatch(toggleAppState());
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

const fetchStoreProductsAction = products => ({
  type: types.FETCH_STORE_PRODUCTS,
  products
});

export const fetchStoreProducts = storeId => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchStoreProducts(storeId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchStoreProductsAction(response.results.data));
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

const fetchAnalyticsAction = analytics => ({
  type: types.FETCH_ANALYTICS,
  analytics
});

export const fetchAnalytics = storeId => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchAnalytics(storeId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchAnalyticsAction(response.results));
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

const fetchProductCategoriesAction = categories => ({
  type: types.FETCH_PRODUCT_CATEGORIES,
  categories
});

export const fetchProductCategories = stateId => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchProductCategories()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(toggleAppState());
        dispatch(fetchProductCategoriesAction(response.result));
      })
      .catch(api.errorHandler);
  };
};

const fetchProductSubCategoriesAction = subCategories => ({
  type: types.FETCH_PRODUCT_SUB_CATEGORIES,
  subCategories
});

export const fetchProductSubCategories = categoryId => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchProductSubCategories(categoryId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchProductSubCategoriesAction(response.result));
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

export const updateProduct = (productId, payload, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .updateProduct(productId, payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success(`Successfully updated product.`);
        dispatch(toggleAppState());
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const removeProduct = productId => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .removeProduct(productId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success(`Successfully deleted product.`);
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};
export const createProductVariant = (payload, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .createProductVariant(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(toggleAppState());
        toast.success("Successfully created product variant");
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const addOrderShippingFee = ({ orderId, payload }, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .addOrderShippingFee(orderId, payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success("Successfully added a shipping fee");
        dispatch(toggleAppState());
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const updateOrder = (
  { orderId, payload, type = "declined" },
  callback
) => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .updateOrder(orderId, payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success(`Successfully ${type} order`);
        dispatch(toggleAppState());
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

const setOrderAction = order => ({
  type: types.FETCH_ORDER,
  order
});

export const getSingleOrder = (orderId, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .getSingleOrder(orderId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(setOrderAction(response.results[0]));
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const confirmSellerDelivery = (orderId, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .confirmSellerDelivery(orderId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success(`Successfully confirmed delivery of an order`);
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};
