import { toast } from "react-toastify";
import auth from "./auth.api";
import metadata from "./metadata.api";
import user from "./user.api";
import store from "./store.api";
import wallet from "./wallet.api";
import bank from "./bank.api";
import bankDetail from "./bankDetail.api";

/**
 * Checks the status of a request.
 * @param {Object} response
 */
async function errorHandler(response) {
  if (response.statusText) {
    let json = await response.json();
    const { status_code } = json;
    if (parseInt(status_code) >= 200 && parseInt(status_code) < 400) return;
    return toast.error(json.message);
  }
}

/**
 * Resolves or rejects a Promise for an issued response status code.
 * @param {Object} response
 * @return {Object<Promise>}
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 400) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}

const toText = response => response.text();
const toJSON = response => response.json();

export default {
  ...auth,
  ...user,
  ...store,
  ...metadata,
  ...wallet,
  ...bank,
  ...bankDetail,
  toText,
  toJSON,
  errorHandler,
  checkStatus
};
