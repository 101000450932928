import React from "react";
import CKEditors from "react-ckeditor-component";

export function RichTextField({
  content,
  error,
  label,
  onBlur,
  afterPaste,
  onChange
}) {
  return (
    <div className="form-group row">
      <label className="col-xl-3 col-sm-4">{label}</label>
      <div className="col-xl-8 col-sm-7 description-sm">
        <CKEditors
          activeclassName="p10"
          content={content}
          onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
          events={{
            blur: onBlur,
            change: onChange,
            afterPaste
          }}
        />
        {error && error.length ? (
          <div className="text-danger">{error}</div>
        ) : null}
      </div>
    </div>
  );
}
