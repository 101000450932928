import * as types from "../constants/ActionTypes.js";
import "react-toastify/dist/ReactToastify.min.css";

const fetchCountriesAction = countries => ({
  type: types.FETCH_COUNTRIES,
  countries
});

export const fetchCountries = () => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchCountries()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchCountriesAction(response.result.countries));
      })
      .catch(api.errorHandler);
  };
};

const fetchCountryStatesAction = states => ({
  type: types.FETCH_COUNTRY_STATES,
  states
});

export const fetchCountryStates = countryId => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchStatesByCountry(countryId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchCountryStatesAction(response.result));
      })
      .catch(api.errorHandler);
  };
};

const fetchStateProvincesAction = provinces => ({
  type: types.FETCH_STATE_PROVINCES,
  provinces
});

export const fetchStateProvinces = stateId => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchProvincesByState(stateId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchStateProvincesAction(response.result));
      })
      .catch(api.errorHandler);
  };
};
