import React, { Component, Fragment } from "react";
import { ShoppingBag, Download, Info } from "react-feather";

export class Notification extends Component {
  render() {
    const { messagesCount } = this.props;
    return (
      <Fragment>
        <ul className="notification-dropdown onhover-show-div p-0">
          {messagesCount > 0 ? (
            <li>
              Notification{" "}
              <span className="badge badge-pill badge-primary pull-right">
                {messagesCount}
              </span>
            </li>
          ) : null}
          <li>
            <div className="media">
              <div className="media-body">
                <h6 className="mt-0 txt-info">
                  <span>
                    <Info />
                  </span>
                  {messagesCount > 0
                    ? "Received new messages"
                    : "No new messages"}
                </h6>
                <p className="mb-0">
                  {messagesCount > 0
                    ? `You've received ${messagesCount} new messages. Check your chats`
                    : "You don't have any new messages at the moment"}
                </p>
              </div>
            </div>
          </li>

          {/* <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0"><span><ShoppingBag /></span>Your order ready for Ship..!</h6>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                    </div>
                                </div>
                            </li> */}
          {/* <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0 txt-success"><span><Download /></span>Download Complete</h6>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0 txt-danger"><span><AlertCircle /></span>250 MB trash files</h6>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="txt-dark"><a href="#">All</a> notification</li> */}
        </ul>
      </Fragment>
    );
  }
}

export default Notification;
