import {
  SET_USERS,
  SET_PROFILE,
  FETCH_ORDER_NOTIFICATIONS,
  UPDATE_WALLET_BALANCE
} from "../constants/ActionTypes";

const initialState = {
  profile: null,
  orderNotifications: [],
  users: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      const { profile } = action;

      return { ...state, profile };

    case SET_USERS:
      const { users } = action;

      return { ...state, users };
    
    case UPDATE_WALLET_BALANCE:
      return { ...state, profile: { ...state.profile, wallet_balance: action.payload } };

    case FETCH_ORDER_NOTIFICATIONS:
      const { orderNotifications } = action;

      return { ...state, orderNotifications };

    default:
      return state;
  }
}
