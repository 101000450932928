import * as types from "../constants/ActionTypes.js";
import { toast } from "react-toastify";
import { toggleAppState } from "./app.actions";
import "react-toastify/dist/ReactToastify.min.css";

export const fetchBanksAction = banks => ({
  type: types.FETCH_BANKS,
  payload: banks
});

export const fetchBanks = () => {
  return (dispatch, getState, { api }) => {
    dispatch(toggleAppState());
    return api
      .fetchBanks()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchBanksAction(response.result));
        dispatch(toggleAppState());
      })
      .catch(api.errorHandler);
  };
};

