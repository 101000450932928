import {
  FETCH_BANK_DETAIL,
} from "../constants/ActionTypes.js";

const initialState = {};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BANK_DETAIL:
      return action.payload;

    default:
      return state;
  }
}
